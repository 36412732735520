define("@glimmer/runtime", ["exports", "@glimmer/util", "@glimmer/global-context", "@glimmer/reference", "@glimmer/vm", "@glimmer/validator", "@glimmer/program", "@glimmer/low-level"], function (_exports, _util, _globalContext, _reference, _vm2, _validator, _program, _lowLevel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AotRuntime = AotRuntime;
  _exports.IDOMChanges = _exports.EnvironmentImpl = _exports.EMPTY_POSITIONAL = _exports.EMPTY_NAMED = _exports.EMPTY_ARGS = _exports.DynamicScopeImpl = _exports.DynamicAttribute = _exports.DOMTreeConstruction = _exports.DOMChanges = _exports.DEFAULT_CAPABILITIES = _exports.CursorImpl = _exports.CurriedComponentDefinition = _exports.ConditionalReference = _exports.ConcreteBounds = void 0;
  _exports.JitRuntime = JitRuntime;
  _exports.UpdatingVM = _exports.UpdatableBlockImpl = _exports.UNDEFINED_REFERENCE = _exports.TEMPLATE_ONLY_COMPONENT = _exports.SimpleDynamicAttribute = _exports.SimpleComponentManager = _exports.SERIALIZATION_FIRST_NODE_STRING = _exports.RemoteLiveBlock = _exports.ReifyPositionalReference = _exports.ReifyNamedReference = _exports.RehydrateBuilder = _exports.PrimitiveReference = _exports.PartialScopeImpl = _exports.NewElementBuilder = _exports.NULL_REFERENCE = _exports.MINIMAL_CAPABILITIES = _exports.LowLevelVM = void 0;
  _exports._destroyChildren = destroyChildren;
  _exports.assertDestroyablesDestroyed = void 0;
  _exports.associateDestroyableChild = associateDestroyableChild;
  _exports.capabilityFlagsFrom = capabilityFlagsFrom;
  _exports.clear = clear;
  _exports.clientBuilder = clientBuilder;
  _exports.createCapturedArgs = createCapturedArgs;
  _exports.curry = curry;
  _exports.destroy = destroy;
  _exports.dynamicAttribute = dynamicAttribute;
  _exports.enableDestroyableTracking = void 0;
  _exports.getDynamicVar = getDynamicVar;
  _exports.hasCapability = hasCapability;
  _exports.inTransaction = inTransaction;
  _exports.isCurriedComponentDefinition = isCurriedComponentDefinition;
  _exports.isDestroyed = isDestroyed;
  _exports.isDestroying = isDestroying;
  _exports.isSerializationFirstNode = isSerializationFirstNode;
  _exports.isWhitespace = isWhitespace;
  _exports.normalizeProperty = normalizeProperty;
  _exports.registerDestructor = registerDestructor;
  _exports.rehydrationBuilder = rehydrationBuilder;
  _exports.reifyArgs = reifyArgs;
  _exports.reifyNamed = reifyNamed;
  _exports.reifyPositional = reifyPositional;
  _exports.renderAot = renderAot;
  _exports.renderAotComponent = renderAotComponent;
  _exports.renderAotMain = renderAotMain;
  _exports.renderJitComponent = renderJitComponent;
  _exports.renderJitMain = renderJitMain;
  _exports.renderSync = renderSync;
  _exports.resetDebuggerCallback = resetDebuggerCallback;
  _exports.setDebuggerCallback = setDebuggerCallback;
  _exports.unregisterDestructor = unregisterDestructor;
  // the VM in other classes, but are not intended to be a part of
  // Glimmer's API.

  var INNER_VM = (0, _util.symbol)('INNER_VM');
  var DESTROYABLE_STACK = (0, _util.symbol)('DESTROYABLE_STACK');
  var STACKS = (0, _util.symbol)('STACKS');
  var REGISTERS = (0, _util.symbol)('REGISTERS');
  var HEAP = (0, _util.symbol)('HEAP');
  var CONSTANTS = (0, _util.symbol)('CONSTANTS');
  var ARGS = (0, _util.symbol)('ARGS');
  var PC = (0, _util.symbol)('PC');
  class CursorImpl {
    constructor(element, nextSibling) {
      this.element = element;
      this.nextSibling = nextSibling;
    }
  }
  _exports.CursorImpl = CursorImpl;
  class ConcreteBounds {
    constructor(parentNode, first, last) {
      this.parentNode = parentNode;
      this.first = first;
      this.last = last;
    }
    parentElement() {
      return this.parentNode;
    }
    firstNode() {
      return this.first;
    }
    lastNode() {
      return this.last;
    }
  }
  _exports.ConcreteBounds = ConcreteBounds;
  class SingleNodeBounds {
    constructor(parentNode, node) {
      this.parentNode = parentNode;
      this.node = node;
    }
    parentElement() {
      return this.parentNode;
    }
    firstNode() {
      return this.node;
    }
    lastNode() {
      return this.node;
    }
  }
  function move(bounds, reference) {
    var parent = bounds.parentElement();
    var first = bounds.firstNode();
    var last = bounds.lastNode();
    var current = first;
    while (true) {
      var next = current.nextSibling;
      parent.insertBefore(current, reference);
      if (current === last) {
        return next;
      }
      current = next;
    }
  }
  function clear(bounds) {
    var parent = bounds.parentElement();
    var first = bounds.firstNode();
    var last = bounds.lastNode();
    var current = first;
    while (true) {
      var next = current.nextSibling;
      parent.removeChild(current);
      if (current === last) {
        return next;
      }
      current = next;
    }
  }
  function normalizeStringValue(value) {
    if (isEmpty(value)) {
      return '';
    }
    return String(value);
  }
  function shouldCoerce(value) {
    return isString(value) || isEmpty(value) || typeof value === 'boolean' || typeof value === 'number';
  }
  function isEmpty(value) {
    return value === null || value === undefined || typeof value.toString !== 'function';
  }
  function isSafeString(value) {
    return typeof value === 'object' && value !== null && typeof value.toHTML === 'function';
  }
  function isNode(value) {
    return typeof value === 'object' && value !== null && typeof value.nodeType === 'number';
  }
  function isFragment(value) {
    return isNode(value) && value.nodeType === 11;
  }
  function isString(value) {
    return typeof value === 'string';
  }
  /*
   * @method normalizeProperty
   * @param element {HTMLElement}
   * @param slotName {String}
   * @returns {Object} { name, type }
   */

  function normalizeProperty(element, slotName) {
    var type, normalized;
    if (slotName in element) {
      normalized = slotName;
      type = 'prop';
    } else {
      var lower = slotName.toLowerCase();
      if (lower in element) {
        type = 'prop';
        normalized = lower;
      } else {
        type = 'attr';
        normalized = slotName;
      }
    }
    if (type === 'prop' && (normalized.toLowerCase() === 'style' || preferAttr(element.tagName, normalized))) {
      type = 'attr';
    }
    return {
      normalized,
      type
    };
  } // * browser bug
  // * strange spec outlier

  var ATTR_OVERRIDES = {
    INPUT: {
      form: true,
      // Chrome 46.0.2464.0: 'autocorrect' in document.createElement('input') === false
      // Safari 8.0.7: 'autocorrect' in document.createElement('input') === false
      // Mobile Safari (iOS 8.4 simulator): 'autocorrect' in document.createElement('input') === true
      autocorrect: true,
      // Chrome 54.0.2840.98: 'list' in document.createElement('input') === true
      // Safari 9.1.3: 'list' in document.createElement('input') === false
      list: true
    },
    // element.form is actually a legitimate readOnly property, that is to be
    // mutated, but must be mutated by setAttribute...
    SELECT: {
      form: true
    },
    OPTION: {
      form: true
    },
    TEXTAREA: {
      form: true
    },
    LABEL: {
      form: true
    },
    FIELDSET: {
      form: true
    },
    LEGEND: {
      form: true
    },
    OBJECT: {
      form: true
    },
    BUTTON: {
      form: true
    }
  };
  function preferAttr(tagName, propName) {
    var tag = ATTR_OVERRIDES[tagName.toUpperCase()];
    return tag && tag[propName.toLowerCase()] || false;
  }
  var badProtocols = ['javascript:', 'vbscript:'];
  var badTags = ['A', 'BODY', 'LINK', 'IMG', 'IFRAME', 'BASE', 'FORM'];
  var badTagsForDataURI = ['EMBED'];
  var badAttributes = ['href', 'src', 'background', 'action'];
  var badAttributesForDataURI = ['src'];
  function has(array, item) {
    return array.indexOf(item) !== -1;
  }
  function checkURI(tagName, attribute) {
    return (tagName === null || has(badTags, tagName)) && has(badAttributes, attribute);
  }
  function checkDataURI(tagName, attribute) {
    if (tagName === null) return false;
    return has(badTagsForDataURI, tagName) && has(badAttributesForDataURI, attribute);
  }
  function requiresSanitization(tagName, attribute) {
    return checkURI(tagName, attribute) || checkDataURI(tagName, attribute);
  }
  var protocolForUrl;
  if (typeof URL === 'object' && URL !== null &&
  // this is super annoying, TS thinks that URL **must** be a function so `URL.parse` check
  // thinks it is `never` without this `as unknown as any`
  typeof URL.parse === 'function') {
    // In Ember-land the `fastboot` package sets the `URL` global to `require('url')`
    // ultimately, this should be changed (so that we can either rely on the natural `URL` global
    // that exists) but for now we have to detect the specifc `FastBoot` case first
    //
    // a future version of `fastboot` will detect if this legacy URL setup is required (by
    // inspecting Ember version) and if new enough, it will avoid shadowing the `URL` global
    // constructor with `require('url')`.
    var nodeURL = URL;
    protocolForUrl = url => {
      var protocol = null;
      if (typeof url === 'string') {
        protocol = nodeURL.parse(url).protocol;
      }
      return protocol === null ? ':' : protocol;
    };
  } else if (typeof URL === 'function') {
    protocolForUrl = _url => {
      try {
        var url = new URL(_url);
        return url.protocol;
      } catch (error) {
        // any non-fully qualified url string will trigger an error (because there is no
        // baseURI that we can provide; in that case we **know** that the protocol is
        // "safe" because it isn't specifically one of the `badProtocols` listed above
        // (and those protocols can never be the default baseURI)
        return ':';
      }
    };
  } else {
    // fallback for IE11 support
    var parsingNode = document.createElement('a');
    protocolForUrl = url => {
      parsingNode.href = url;
      return parsingNode.protocol;
    };
  }
  function sanitizeAttributeValue(element, attribute, value) {
    var tagName = null;
    if (value === null || value === undefined) {
      return value;
    }
    if (isSafeString(value)) {
      return value.toHTML();
    }
    if (!element) {
      tagName = null;
    } else {
      tagName = element.tagName.toUpperCase();
    }
    var str = normalizeStringValue(value);
    if (checkURI(tagName, attribute)) {
      var protocol = protocolForUrl(str);
      if (has(badProtocols, protocol)) {
        return `unsafe:${str}`;
      }
    }
    if (checkDataURI(tagName, attribute)) {
      return `unsafe:${str}`;
    }
    return str;
  }
  function dynamicAttribute(element, attr, namespace, isTrusting = false) {
    var {
      tagName,
      namespaceURI
    } = element;
    var attribute = {
      element,
      name: attr,
      namespace
    };
    if (false /* DEBUG */ && attr === 'style' && !isTrusting) {
      return new DebugStyleAttributeManager(attribute);
    }
    if (namespaceURI === "http://www.w3.org/2000/svg"
    /* SVG */) {
      return buildDynamicAttribute(tagName, attr, attribute);
    }
    var {
      type,
      normalized
    } = normalizeProperty(element, attr);
    if (type === 'attr') {
      return buildDynamicAttribute(tagName, normalized, attribute);
    } else {
      return buildDynamicProperty(tagName, normalized, attribute);
    }
  }
  function buildDynamicAttribute(tagName, name, attribute) {
    if (requiresSanitization(tagName, name)) {
      return new SafeDynamicAttribute(attribute);
    } else {
      return new SimpleDynamicAttribute(attribute);
    }
  }
  function buildDynamicProperty(tagName, name, attribute) {
    if (requiresSanitization(tagName, name)) {
      return new SafeDynamicProperty(name, attribute);
    }
    if (isUserInputValue(tagName, name)) {
      return new InputValueDynamicAttribute(name, attribute);
    }
    if (isOptionSelected(tagName, name)) {
      return new OptionSelectedDynamicAttribute(name, attribute);
    }
    return new DefaultDynamicProperty(name, attribute);
  }
  class DynamicAttribute {
    constructor(attribute) {
      this.attribute = attribute;
    }
  }
  _exports.DynamicAttribute = DynamicAttribute;
  class SimpleDynamicAttribute extends DynamicAttribute {
    set(dom, value, _env) {
      var normalizedValue = normalizeValue(value);
      if (normalizedValue !== null) {
        var {
          name: _name2,
          namespace
        } = this.attribute;
        dom.__setAttribute(_name2, normalizedValue, namespace);
      }
    }
    update(value, _env) {
      var normalizedValue = normalizeValue(value);
      var {
        element,
        name
      } = this.attribute;
      if (normalizedValue === null) {
        element.removeAttribute(name);
      } else {
        element.setAttribute(name, normalizedValue);
      }
    }
  }
  _exports.SimpleDynamicAttribute = SimpleDynamicAttribute;
  class DefaultDynamicProperty extends DynamicAttribute {
    constructor(normalizedName, attribute) {
      super(attribute);
      this.normalizedName = normalizedName;
    }
    set(dom, value, _env) {
      if (value !== null && value !== undefined) {
        this.value = value;
        dom.__setProperty(this.normalizedName, value);
      }
    }
    update(value, _env) {
      var {
        element
      } = this.attribute;
      if (this.value !== value) {
        element[this.normalizedName] = this.value = value;
        if (value === null || value === undefined) {
          this.removeAttribute();
        }
      }
    }
    removeAttribute() {
      // TODO this sucks but to preserve properties first and to meet current
      // semantics we must do this.
      var {
        element,
        namespace
      } = this.attribute;
      if (namespace) {
        element.removeAttributeNS(namespace, this.normalizedName);
      } else {
        element.removeAttribute(this.normalizedName);
      }
    }
  }
  class SafeDynamicProperty extends DefaultDynamicProperty {
    set(dom, value, env) {
      var {
        element,
        name
      } = this.attribute;
      var sanitized = sanitizeAttributeValue(element, name, value);
      super.set(dom, sanitized, env);
    }
    update(value, env) {
      var {
        element,
        name
      } = this.attribute;
      var sanitized = sanitizeAttributeValue(element, name, value);
      super.update(sanitized, env);
    }
  }
  class SafeDynamicAttribute extends SimpleDynamicAttribute {
    set(dom, value, env) {
      var {
        element,
        name
      } = this.attribute;
      var sanitized = sanitizeAttributeValue(element, name, value);
      super.set(dom, sanitized, env);
    }
    update(value, env) {
      var {
        element,
        name
      } = this.attribute;
      var sanitized = sanitizeAttributeValue(element, name, value);
      super.update(sanitized, env);
    }
  }
  class InputValueDynamicAttribute extends DefaultDynamicProperty {
    set(dom, value) {
      dom.__setProperty('value', normalizeStringValue(value));
    }
    update(value) {
      var input = this.attribute.element;
      var currentValue = input.value;
      var normalizedValue = normalizeStringValue(value);
      if (currentValue !== normalizedValue) {
        input.value = normalizedValue;
      }
    }
  }
  class OptionSelectedDynamicAttribute extends DefaultDynamicProperty {
    set(dom, value) {
      if (value !== null && value !== undefined && value !== false) {
        dom.__setProperty('selected', true);
      }
    }
    update(value) {
      var option = this.attribute.element;
      if (value) {
        option.selected = true;
      } else {
        option.selected = false;
      }
    }
  }
  function isOptionSelected(tagName, attribute) {
    return tagName === 'OPTION' && attribute === 'selected';
  }
  function isUserInputValue(tagName, attribute) {
    return (tagName === 'INPUT' || tagName === 'TEXTAREA') && attribute === 'value';
  }
  function normalizeValue(value) {
    if (value === false || value === undefined || value === null || typeof value.toString === 'undefined') {
      return null;
    }
    if (value === true) {
      return '';
    } // onclick function etc in SSR

    if (typeof value === 'function') {
      return null;
    }
    return String(value);
  }
  var DebugStyleAttributeManager;
  if (false /* DEBUG */) {
    DebugStyleAttributeManager = class extends SimpleDynamicAttribute {
      set(dom, value, env) {
        (0, _globalContext.warnIfStyleNotTrusted)(value);
        super.set(dom, value, env);
      }
      update(value, env) {
        (0, _globalContext.warnIfStyleNotTrusted)(value);
        super.update(value, env);
      }
    };
  }
  var DESTROYABLE_META = new WeakMap();
  function push(collection, newItem) {
    if (collection === null) {
      return newItem;
    } else if (Array.isArray(collection)) {
      collection.push(newItem);
      return collection;
    } else {
      return [collection, newItem];
    }
  }
  function iterate(collection, fn) {
    if (Array.isArray(collection)) {
      for (var i = 0; i < collection.length; i++) {
        fn(collection[i]);
      }
    } else if (collection !== null) {
      fn(collection);
    }
  }
  function remove(collection, item, message) {
    if (false /* DEBUG */) {
      var collectionIsItem = collection === item;
      var collectionContainsItem = Array.isArray(collection) && collection.indexOf(item) !== -1;
      if (!collectionIsItem && !collectionContainsItem) {
        throw new Error(String(message));
      }
    }
    if (Array.isArray(collection) && collection.length > 1) {
      var index = collection.indexOf(item);
      collection.splice(index, 1);
      return collection;
    } else {
      return null;
    }
  }
  function getDestroyableMeta(destroyable) {
    var meta = DESTROYABLE_META.get(destroyable);
    if (meta === undefined) {
      meta = {
        parents: null,
        children: null,
        eagerDestructors: null,
        destructors: null,
        state: 0
        /* Live */
      };
      if (false /* DEBUG */) {
        meta.source = destroyable;
      }
      DESTROYABLE_META.set(destroyable, meta);
    }
    return meta;
  }
  function associateDestroyableChild(parent, child) {
    if (false /* DEBUG */ && isDestroying(parent)) {
      throw new Error('Attempted to associate a destroyable child with an object that is already destroying or destroyed');
    }
    var parentMeta = getDestroyableMeta(parent);
    var childMeta = getDestroyableMeta(child);
    parentMeta.children = push(parentMeta.children, child);
    childMeta.parents = push(childMeta.parents, parent);
    return child;
  }
  function registerDestructor(destroyable, destructor, eager = false) {
    if (false /* DEBUG */ && isDestroying(destroyable)) {
      throw new Error('Attempted to register a destructor with an object that is already destroying or destroyed');
    }
    var meta = getDestroyableMeta(destroyable);
    var destructorsKey = eager === true ? 'eagerDestructors' : 'destructors';
    meta[destructorsKey] = push(meta[destructorsKey], destructor);
    return destructor;
  }
  function unregisterDestructor(destroyable, destructor, eager = false) {
    if (false /* DEBUG */ && isDestroying(destroyable)) {
      throw new Error('Attempted to unregister a destructor with an object that is already destroying or destroyed');
    }
    var meta = getDestroyableMeta(destroyable);
    var destructorsKey = eager === true ? 'eagerDestructors' : 'destructors';
    meta[destructorsKey] = remove(meta[destructorsKey], destructor, false /* DEBUG */ && 'attempted to remove a destructor that was not registered with the destroyable');
  } ////////////

  function destroy(destroyable) {
    var meta = getDestroyableMeta(destroyable);
    if (meta.state >= 1
    /* Destroying */) return;
    var {
      parents,
      children,
      eagerDestructors,
      destructors
    } = meta;
    meta.state = 1
    /* Destroying */;
    iterate(children, destroy);
    iterate(eagerDestructors, destructor => destructor(destroyable));
    iterate(destructors, destructor => (0, _globalContext.scheduleDestroy)(destroyable, destructor));
    (0, _globalContext.scheduleDestroyed)(() => {
      iterate(parents, parent => removeChildFromParent(destroyable, parent));
      meta.state = 2
      /* Destroyed */;
    });
  }
  function removeChildFromParent(child, parent) {
    var parentMeta = getDestroyableMeta(parent);
    if (parentMeta.state === 0
    /* Live */) {
      parentMeta.children = remove(parentMeta.children, child, false /* DEBUG */ && "attempted to remove child from parent, but the parent's children did not contain the child. This is likely a bug with destructors.");
    }
  }
  function destroyChildren(destroyable) {
    var {
      children
    } = getDestroyableMeta(destroyable);
    iterate(children, destroy);
  }
  function isDestroying(destroyable) {
    var meta = DESTROYABLE_META.get(destroyable);
    return meta === undefined ? false : meta.state >= 1
    /* Destroying */;
  }
  function isDestroyed(destroyable) {
    var meta = DESTROYABLE_META.get(destroyable);
    return meta === undefined ? false : meta.state >= 2
    /* Destroyed */;
  } ////////////

  var enableDestroyableTracking;
  var assertDestroyablesDestroyed;
  if (false /* DEBUG */) {
    var isTesting = false;
    _exports.enableDestroyableTracking = enableDestroyableTracking = () => {
      if (isTesting) {
        throw new Error('Attempted to start destroyable testing, but you did not end the previous destroyable test. Did you forget to call `assertDestroyablesDestroyed()`');
      }
      isTesting = true;
      DESTROYABLE_META = new Map();
    };
    _exports.assertDestroyablesDestroyed = assertDestroyablesDestroyed = () => {
      if (!isTesting) {
        throw new Error('Attempted to assert destroyables destroyed, but you did not start a destroyable test. Did you forget to call `enableDestroyableTracking()`');
      }
      isTesting = false;
      var map = DESTROYABLE_META;
      DESTROYABLE_META = new WeakMap();
      var undestroyed = [];
      map.forEach(meta => {
        if (meta.state !== 2
        /* Destroyed */) {
          undestroyed.push(meta.source);
        }
      });
      if (undestroyed.length > 0) {
        var objectsToString = undestroyed.map(_util.debugToString).join('\n    ');
        var error = new Error(`Some destroyables were not destroyed during this test:\n    ${objectsToString}`);
        error.destroyables = undestroyed;
        throw error;
      }
    };
  }
  var _a;
  class First {
    constructor(node) {
      this.node = node;
    }
    firstNode() {
      return this.node;
    }
  }
  class Last {
    constructor(node) {
      this.node = node;
    }
    lastNode() {
      return this.node;
    }
  }
  var CURSOR_STACK = (0, _util.symbol)('CURSOR_STACK');
  class NewElementBuilder {
    constructor(env, parentNode, nextSibling) {
      this.constructing = null;
      this.operations = null;
      this[_a] = new _util.Stack();
      this.modifierStack = new _util.Stack();
      this.blockStack = new _util.Stack();
      this.pushElement(parentNode, nextSibling);
      this.env = env;
      this.dom = env.getAppendOperations();
      this.updateOperations = env.getDOM();
    }
    static forInitialRender(env, cursor) {
      return new this(env, cursor.element, cursor.nextSibling).initialize();
    }
    static resume(env, block) {
      var parentNode = block.parentElement();
      var nextSibling = block.reset(env);
      var stack = new this(env, parentNode, nextSibling).initialize();
      stack.pushLiveBlock(block);
      return stack;
    }
    initialize() {
      this.pushSimpleBlock();
      return this;
    }
    debugBlocks() {
      return this.blockStack.toArray();
    }
    get element() {
      return this[CURSOR_STACK].current.element;
    }
    get nextSibling() {
      return this[CURSOR_STACK].current.nextSibling;
    }
    get hasBlocks() {
      return this.blockStack.size > 0;
    }
    block() {
      return this.blockStack.current;
    }
    popElement() {
      this[CURSOR_STACK].pop();
      this[CURSOR_STACK].current;
    }
    pushSimpleBlock() {
      return this.pushLiveBlock(new SimpleLiveBlock(this.element));
    }
    pushUpdatableBlock() {
      return this.pushLiveBlock(new UpdatableBlockImpl(this.element));
    }
    pushBlockList(list) {
      return this.pushLiveBlock(new LiveBlockList(this.element, list));
    }
    pushLiveBlock(block, isRemote = false) {
      var current = this.blockStack.current;
      if (current !== null) {
        if (!isRemote) {
          current.didAppendBounds(block);
        }
      }
      this.__openBlock();
      this.blockStack.push(block);
      return block;
    }
    popBlock() {
      this.block().finalize(this);
      this.__closeBlock();
      return this.blockStack.pop();
    }
    __openBlock() {}
    __closeBlock() {} // todo return seems unused

    openElement(tag) {
      var element = this.__openElement(tag);
      this.constructing = element;
      return element;
    }
    __openElement(tag) {
      return this.dom.createElement(tag, this.element);
    }
    flushElement(modifiers) {
      var parent = this.element;
      var element = this.constructing;
      this.__flushElement(parent, element);
      this.constructing = null;
      this.operations = null;
      this.pushModifiers(modifiers);
      this.pushElement(element, null);
      this.didOpenElement(element);
    }
    __flushElement(parent, constructing) {
      this.dom.insertBefore(parent, constructing, this.nextSibling);
    }
    closeElement() {
      this.willCloseElement();
      this.popElement();
      return this.popModifiers();
    }
    pushRemoteElement(element, guid, insertBefore) {
      return this.__pushRemoteElement(element, guid, insertBefore);
    }
    __pushRemoteElement(element, _guid, insertBefore) {
      this.pushElement(element, insertBefore);
      if (insertBefore === undefined) {
        while (element.lastChild) {
          element.removeChild(element.lastChild);
        }
      }
      var block = new RemoteLiveBlock(element);
      return this.pushLiveBlock(block, true);
    }
    popRemoteElement() {
      this.popBlock();
      this.popElement();
    }
    pushElement(element, nextSibling = null) {
      this[CURSOR_STACK].push(new CursorImpl(element, nextSibling));
    }
    pushModifiers(modifiers) {
      this.modifierStack.push(modifiers);
    }
    popModifiers() {
      return this.modifierStack.pop();
    }
    didAppendBounds(bounds) {
      this.block().didAppendBounds(bounds);
      return bounds;
    }
    didAppendNode(node) {
      this.block().didAppendNode(node);
      return node;
    }
    didOpenElement(element) {
      this.block().openElement(element);
      return element;
    }
    willCloseElement() {
      this.block().closeElement();
    }
    appendText(string) {
      return this.didAppendNode(this.__appendText(string));
    }
    __appendText(text) {
      var {
        dom,
        element,
        nextSibling
      } = this;
      var node = dom.createTextNode(text);
      dom.insertBefore(element, node, nextSibling);
      return node;
    }
    __appendNode(node) {
      this.dom.insertBefore(this.element, node, this.nextSibling);
      return node;
    }
    __appendFragment(fragment) {
      var first = fragment.firstChild;
      if (first) {
        var ret = new ConcreteBounds(this.element, first, fragment.lastChild);
        this.dom.insertBefore(this.element, fragment, this.nextSibling);
        return ret;
      } else {
        return new SingleNodeBounds(this.element, this.__appendComment(''));
      }
    }
    __appendHTML(html) {
      return this.dom.insertHTMLBefore(this.element, this.nextSibling, html);
    }
    appendDynamicHTML(value) {
      var bounds = this.trustedContent(value);
      this.didAppendBounds(bounds);
    }
    appendDynamicText(value) {
      var node = this.untrustedContent(value);
      this.didAppendNode(node);
      return node;
    }
    appendDynamicFragment(value) {
      var bounds = this.__appendFragment(value);
      this.didAppendBounds(bounds);
    }
    appendDynamicNode(value) {
      var node = this.__appendNode(value);
      var bounds = new SingleNodeBounds(this.element, node);
      this.didAppendBounds(bounds);
    }
    trustedContent(value) {
      return this.__appendHTML(value);
    }
    untrustedContent(value) {
      return this.__appendText(value);
    }
    appendComment(string) {
      return this.didAppendNode(this.__appendComment(string));
    }
    __appendComment(string) {
      var {
        dom,
        element,
        nextSibling
      } = this;
      var node = dom.createComment(string);
      dom.insertBefore(element, node, nextSibling);
      return node;
    }
    __setAttribute(name, value, namespace) {
      this.dom.setAttribute(this.constructing, name, value, namespace);
    }
    __setProperty(name, value) {
      this.constructing[name] = value;
    }
    setStaticAttribute(name, value, namespace) {
      this.__setAttribute(name, value, namespace);
    }
    setDynamicAttribute(name, value, trusting, namespace) {
      var element = this.constructing;
      var attribute = dynamicAttribute(element, name, namespace, trusting);
      attribute.set(this, value, this.env);
      return attribute;
    }
  }
  _exports.NewElementBuilder = NewElementBuilder;
  _a = CURSOR_STACK;
  class SimpleLiveBlock {
    constructor(parent) {
      this.parent = parent;
      this.first = null;
      this.last = null;
      this.nesting = 0;
    }
    parentElement() {
      return this.parent;
    }
    firstNode() {
      var first = this.first;
      return first.firstNode();
    }
    lastNode() {
      var last = this.last;
      return last.lastNode();
    }
    openElement(element) {
      this.didAppendNode(element);
      this.nesting++;
    }
    closeElement() {
      this.nesting--;
    }
    didAppendNode(node) {
      if (this.nesting !== 0) return;
      if (!this.first) {
        this.first = new First(node);
      }
      this.last = new Last(node);
    }
    didAppendBounds(bounds) {
      if (this.nesting !== 0) return;
      if (!this.first) {
        this.first = bounds;
      }
      this.last = bounds;
    }
    finalize(stack) {
      if (this.first === null) {
        stack.appendComment('');
      }
    }
  }
  class RemoteLiveBlock extends SimpleLiveBlock {
    constructor(parent) {
      super(parent);
      registerDestructor(this, () => {
        // In general, you only need to clear the root of a hierarchy, and should never
        // need to clear any child nodes. This is an important constraint that gives us
        // a strong guarantee that clearing a subtree is a single DOM operation.
        //
        // Because remote blocks are not normally physically nested inside of the tree
        // that they are logically nested inside, we manually clear remote blocks when
        // a logical parent is cleared.
        //
        // HOWEVER, it is currently possible for a remote block to be physically nested
        // inside of the block it is logically contained inside of. This happens when
        // the remote block is appended to the end of the application's entire element.
        //
        // The problem with that scenario is that Glimmer believes that it owns more of
        // the DOM than it actually does. The code is attempting to write past the end
        // of the Glimmer-managed root, but Glimmer isn't aware of that.
        //
        // The correct solution to that problem is for Glimmer to be aware of the end
        // of the bounds that it owns, and once we make that change, this check could
        // be removed.
        //
        // For now, a more targeted fix is to check whether the node was already removed
        // and avoid clearing the node if it was. In most cases this shouldn't happen,
        // so this might hide bugs where the code clears nested nodes unnecessarily,
        // so we should eventually try to do the correct fix.
        if (this.parentElement() === this.firstNode().parentNode) {
          clear(this);
        }
      });
    }
  }
  _exports.RemoteLiveBlock = RemoteLiveBlock;
  class UpdatableBlockImpl extends SimpleLiveBlock {
    reset() {
      destroy(this);
      var nextSibling = clear(this);
      this.first = null;
      this.last = null;
      this.nesting = 0;
      return nextSibling;
    }
  } // FIXME: All the noops in here indicate a modelling problem
  _exports.UpdatableBlockImpl = UpdatableBlockImpl;
  class LiveBlockList {
    constructor(parent, boundList) {
      this.parent = parent;
      this.boundList = boundList;
      this.parent = parent;
      this.boundList = boundList;
    }
    parentElement() {
      return this.parent;
    }
    firstNode() {
      var head = this.boundList[0];
      return head.firstNode();
    }
    lastNode() {
      var boundList = this.boundList;
      var tail = boundList[boundList.length - 1];
      return tail.lastNode();
    }
    openElement(_element) {}
    closeElement() {}
    didAppendNode(_node) {}
    didAppendBounds(_bounds) {}
    finalize(_stack) {}
  }
  function clientBuilder(env, cursor) {
    return NewElementBuilder.forInitialRender(env, cursor);
  }
  class PrimitiveReference extends _reference.ConstReference {
    static create(value) {
      if (value === undefined) {
        return UNDEFINED_REFERENCE;
      } else if (value === null) {
        return NULL_REFERENCE;
      } else if (value === true) {
        return TRUE_REFERENCE;
      } else if (value === false) {
        return FALSE_REFERENCE;
      } else if (typeof value === 'number') {
        return new ValueReference(value);
      } else {
        return new StringReference(value);
      }
    }
    constructor(value) {
      super(value);
    }
    get(_key) {
      return UNDEFINED_REFERENCE;
    }
  }
  _exports.PrimitiveReference = PrimitiveReference;
  class StringReference extends PrimitiveReference {
    constructor() {
      super(...arguments);
      this.lengthReference = null;
    }
    get(key) {
      if (key === 'length') {
        var {
          lengthReference
        } = this;
        if (lengthReference === null) {
          lengthReference = this.lengthReference = new ValueReference(this.inner.length);
        }
        return lengthReference;
      } else {
        return super.get(key);
      }
    }
  }
  class ValueReference extends PrimitiveReference {
    constructor(value) {
      super(value);
    }
  }
  var UNDEFINED_REFERENCE = _exports.UNDEFINED_REFERENCE = new ValueReference(undefined);
  var NULL_REFERENCE = _exports.NULL_REFERENCE = new ValueReference(null);
  var TRUE_REFERENCE = new ValueReference(true);
  var FALSE_REFERENCE = new ValueReference(false);
  class ConditionalReference extends _reference.CachedReference {
    constructor(inner, toBool$$1 = _globalContext.toBool) {
      super();
      this.inner = inner;
      this.toBool = toBool$$1;
    }
    compute() {
      var {
        inner,
        toBool: toBool$$1
      } = this;
      return toBool$$1(inner.value());
    }
  }
  _exports.ConditionalReference = ConditionalReference;
  class DynamicScopeImpl {
    constructor(bucket) {
      if (bucket) {
        this.bucket = (0, _util.assign)({}, bucket);
      } else {
        this.bucket = {};
      }
    }
    get(key) {
      return this.bucket[key];
    }
    set(key, reference) {
      return this.bucket[key] = reference;
    }
    child() {
      return new DynamicScopeImpl(this.bucket);
    }
  }
  _exports.DynamicScopeImpl = DynamicScopeImpl;
  class PartialScopeImpl {
    constructor(
    // the 0th slot is `self`
    slots, callerScope,
    // named arguments and blocks passed to a layout that uses eval
    evalScope,
    // locals in scope when the partial was invoked
    partialMap) {
      this.slots = slots;
      this.callerScope = callerScope;
      this.evalScope = evalScope;
      this.partialMap = partialMap;
    }
    static root(self, size = 0) {
      var refs = new Array(size + 1);
      for (var i = 0; i <= size; i++) {
        refs[i] = UNDEFINED_REFERENCE;
      }
      return new PartialScopeImpl(refs, null, null, null).init({
        self
      });
    }
    static sized(size = 0) {
      var refs = new Array(size + 1);
      for (var i = 0; i <= size; i++) {
        refs[i] = UNDEFINED_REFERENCE;
      }
      return new PartialScopeImpl(refs, null, null, null);
    }
    init({
      self
    }) {
      this.slots[0] = self;
      return this;
    }
    getSelf() {
      return this.get(0);
    }
    getSymbol(symbol$$1) {
      return this.get(symbol$$1);
    }
    getBlock(symbol$$1) {
      var block = this.get(symbol$$1);
      return block === UNDEFINED_REFERENCE ? null : block;
    }
    getEvalScope() {
      return this.evalScope;
    }
    getPartialMap() {
      return this.partialMap;
    }
    bind(symbol$$1, value) {
      this.set(symbol$$1, value);
    }
    bindSelf(self) {
      this.set(0, self);
    }
    bindSymbol(symbol$$1, value) {
      this.set(symbol$$1, value);
    }
    bindBlock(symbol$$1, value) {
      this.set(symbol$$1, value);
    }
    bindEvalScope(map) {
      this.evalScope = map;
    }
    bindPartialMap(map) {
      this.partialMap = map;
    }
    bindCallerScope(scope) {
      this.callerScope = scope;
    }
    getCallerScope() {
      return this.callerScope;
    }
    child() {
      return new PartialScopeImpl(this.slots.slice(), this.callerScope, this.evalScope, this.partialMap);
    }
    get(index) {
      if (index >= this.slots.length) {
        throw new RangeError(`BUG: cannot get $${index} from scope; length=${this.slots.length}`);
      }
      return this.slots[index];
    }
    set(index, value) {
      if (index >= this.slots.length) {
        throw new RangeError(`BUG: cannot get $${index} from scope; length=${this.slots.length}`);
      }
      this.slots[index] = value;
    }
  }
  _exports.PartialScopeImpl = PartialScopeImpl;
  class AppendOpcodes {
    constructor() {
      this.evaluateOpcode = (0, _util.fillNulls)(107
      /* Size */).slice();
    }
    add(name, evaluate, kind = 'syscall') {
      this.evaluateOpcode[name] = {
        syscall: kind !== 'machine',
        evaluate
      };
    }
    debugBefore(vm, opcode) {
      var params = undefined;
      var opName = undefined;
      var sp;
      return {
        sp: sp,
        pc: vm.fetchValue(_vm2.$pc),
        name: opName,
        params,
        type: opcode.type,
        isMachine: opcode.isMachine,
        size: opcode.size,
        state: undefined
      };
    }
    debugAfter(vm, pre) {}
    evaluate(vm, opcode, type) {
      var operation = this.evaluateOpcode[type];
      if (operation.syscall) {
        operation.evaluate(vm, opcode);
      } else {
        operation.evaluate(vm[INNER_VM], opcode);
      }
    }
  }
  var APPEND_OPCODES = new AppendOpcodes();
  class AbstractOpcode {
    constructor() {
      (0, _util.initializeGuid)(this);
    }
  }
  class UpdatingOpcode extends AbstractOpcode {}
  class ConcatReference extends _reference.CachedReference {
    constructor(parts) {
      super();
      this.parts = parts;
    }
    compute() {
      var parts = new Array();
      for (var i = 0; i < this.parts.length; i++) {
        var value = this.parts[i].value();
        if (value !== null && value !== undefined) {
          parts[i] = castToString(value);
        }
      }
      if (parts.length > 0) {
        return parts.join('');
      }
      return null;
    }
  }
  function castToString(value) {
    if (typeof value.toString !== 'function') {
      return '';
    }
    return String(value);
  }
  APPEND_OPCODES.add(16
  /* Helper */, (vm, {
    op1: handle
  }) => {
    var stack = vm.stack;
    var helper = vm.runtime.resolver.resolve(handle);
    var args = stack.popJs();
    var value = helper(args, vm);
    vm.loadValue(_vm2.$v0, value);
  });
  APPEND_OPCODES.add(22
  /* GetVariable */, (vm, {
    op1: symbol$$1
  }) => {
    var expr = vm.referenceForSymbol(symbol$$1);
    vm.stack.pushJs(expr);
  });
  APPEND_OPCODES.add(19
  /* SetVariable */, (vm, {
    op1: symbol$$1
  }) => {
    var expr = vm.stack.pop();
    vm.scope().bindSymbol(symbol$$1, expr);
  });
  APPEND_OPCODES.add(21
  /* SetJitBlock */, (vm, {
    op1: symbol$$1
  }) => {
    var handle = vm.stack.popJs();
    var scope = vm.stack.popJs();
    var table = vm.stack.popJs();
    var block = table ? [handle, scope, table] : null;
    vm.scope().bindBlock(symbol$$1, block);
  }, 'jit');
  APPEND_OPCODES.add(20
  /* SetAotBlock */, (vm, {
    op1: symbol$$1
  }) => {
    // In DEBUG handles could be ErrHandle objects
    var handle = false /* DEBUG */ ? vm.stack.pop() : vm.stack.popSmallInt();
    var scope = vm.stack.popJs();
    var table = vm.stack.popJs();
    var block = table ? [handle, scope, table] : null;
    vm.scope().bindBlock(symbol$$1, block);
  });
  APPEND_OPCODES.add(105
  /* ResolveMaybeLocal */, (vm, {
    op1: _name
  }) => {
    var name = vm[CONSTANTS].getValue(_name);
    var locals = vm.scope().getPartialMap();
    var ref = locals[name];
    if (ref === undefined) {
      ref = vm.getSelf().get(name);
    }
    vm.stack.pushJs(ref);
  });
  APPEND_OPCODES.add(37
  /* RootScope */, (vm, {
    op1: symbols
  }) => {
    vm.pushRootScope(symbols);
  });
  APPEND_OPCODES.add(23
  /* GetProperty */, (vm, {
    op1: _key
  }) => {
    var key = vm[CONSTANTS].getValue(_key);
    var expr = vm.stack.popJs();
    vm.stack.pushJs(expr.get(key));
  });
  APPEND_OPCODES.add(24
  /* GetBlock */, (vm, {
    op1: _block
  }) => {
    var {
      stack
    } = vm;
    var block = vm.scope().getBlock(_block);
    if (block === null) {
      stack.pushNull();
    } else {
      stack.pushJs(block);
    }
  });
  APPEND_OPCODES.add(25
  /* JitSpreadBlock */, vm => {
    var {
      stack
    } = vm;
    var block = stack.popJs();
    if (block && !isUndefinedReference(block)) {
      var [handleOrCompilable, scope, table] = block;
      stack.pushJs(table);
      stack.pushJs(scope);
      if (typeof handleOrCompilable === 'number') {
        stack.pushSmallInt(handleOrCompilable);
      } else {
        stack.pushJs(handleOrCompilable);
      }
    } else {
      stack.pushNull();
      stack.pushNull();
      stack.pushNull();
    }
  });
  function isUndefinedReference(input) {
    return input === UNDEFINED_REFERENCE;
  }
  APPEND_OPCODES.add(26
  /* HasBlock */, vm => {
    var {
      stack
    } = vm;
    var block = stack.pop();
    if (block && !isUndefinedReference(block)) {
      stack.pushJs(TRUE_REFERENCE);
    } else {
      stack.pushJs(FALSE_REFERENCE);
    }
  });
  APPEND_OPCODES.add(27
  /* HasBlockParams */, vm => {
    // FIXME(mmun): should only need to push the symbol table
    var block = vm.stack.pop();
    var scope = vm.stack.popJs();
    var table = vm.stack.popJs();
    var hasBlockParams = table && table.parameters.length;
    vm.stack.pushJs(hasBlockParams ? TRUE_REFERENCE : FALSE_REFERENCE);
  });
  APPEND_OPCODES.add(28
  /* Concat */, (vm, {
    op1: count
  }) => {
    var out = new Array(count);
    for (var i = count; i > 0; i--) {
      var offset = i - 1;
      out[offset] = vm.stack.pop();
    }
    vm.stack.pushJs(new ConcatReference(out));
  });
  /**
   * Converts a ComponentCapabilities object into a 32-bit integer representation.
   */

  function capabilityFlagsFrom(capabilities) {
    return 0 | (capabilities.dynamicLayout ? 1
    /* DynamicLayout */ : 0) | (capabilities.dynamicTag ? 2
    /* DynamicTag */ : 0) | (capabilities.prepareArgs ? 4
    /* PrepareArgs */ : 0) | (capabilities.createArgs ? 8
    /* CreateArgs */ : 0) | (capabilities.attributeHook ? 16
    /* AttributeHook */ : 0) | (capabilities.elementHook ? 32
    /* ElementHook */ : 0) | (capabilities.dynamicScope ? 64
    /* DynamicScope */ : 0) | (capabilities.createCaller ? 128
    /* CreateCaller */ : 0) | (capabilities.updateHook ? 256
    /* UpdateHook */ : 0) | (capabilities.createInstance ? 512
    /* CreateInstance */ : 0) | (capabilities.wrapped ? 1024
    /* Wrapped */ : 0) | (capabilities.willDestroy ? 2048
    /* WillDestroy */ : 0);
  }
  function managerHasCapability(_manager, capabilities, capability) {
    return !!(capabilities & capability);
  }
  function hasCapability(capabilities, capability) {
    return !!(capabilities & capability);
  }
  var _a$1;
  var CURRIED_COMPONENT_DEFINITION_BRAND = (0, _util.symbol)('CURRIED COMPONENT DEFINITION');
  function isCurriedComponentDefinition(definition) {
    return !!(definition && definition[CURRIED_COMPONENT_DEFINITION_BRAND]);
  }
  function isComponentDefinition(definition) {
    return !!(definition && definition[CURRIED_COMPONENT_DEFINITION_BRAND]);
  }
  class CurriedComponentDefinition {
    /** @internal */
    constructor(inner, args) {
      this.inner = inner;
      this.args = args;
      this[_a$1] = true;
    }
    unwrap(args) {
      args.realloc(this.offset);
      var definition = this;
      while (true) {
        var {
          args: curriedArgs,
          inner
        } = definition;
        if (curriedArgs) {
          args.positional.prepend(curriedArgs.positional);
          args.named.merge(curriedArgs.named);
        }
        if (!isCurriedComponentDefinition(inner)) {
          return inner;
        }
        definition = inner;
      }
    }
    /** @internal */

    get offset() {
      var {
        inner,
        args
      } = this;
      var length = args ? args.positional.length : 0;
      return isCurriedComponentDefinition(inner) ? length + inner.offset : length;
    }
  }
  _exports.CurriedComponentDefinition = CurriedComponentDefinition;
  _a$1 = CURRIED_COMPONENT_DEFINITION_BRAND;
  function curry(spec, args = null) {
    return new CurriedComponentDefinition(spec, args);
  }
  function resolveComponent(resolver, name, meta) {
    var definition = resolver.lookupComponent(name, meta);
    return definition;
  }
  class ClassListReference extends _reference.CachedReference {
    constructor(list) {
      super();
      this.list = list;
      this.list = list;
    }
    compute() {
      var ret = [];
      var {
        list
      } = this;
      for (var i = 0; i < list.length; i++) {
        var value = normalizeStringValue(list[i].value());
        if (value) ret.push(value);
      }
      return ret.length === 0 ? null : ret.join(' ');
    }
  }
  class CurryComponentReference {
    constructor(inner, resolver, meta, args) {
      this.inner = inner;
      this.resolver = resolver;
      this.meta = meta;
      this.args = args;
      this.lastValue = null;
      this.lastDefinition = null;
    }
    value() {
      var {
        inner,
        lastValue
      } = this;
      var value = inner.value();
      if (value === lastValue) {
        return this.lastDefinition;
      }
      var definition = null;
      if (isCurriedComponentDefinition(value)) {
        definition = value;
      } else if (typeof value === 'string' && value) {
        var {
          resolver,
          meta
        } = this;
        definition = resolveComponent(resolver, value, meta);
      }
      definition = this.curry(definition);
      this.lastValue = value;
      this.lastDefinition = definition;
      return definition;
    }
    isConst() {
      return false;
    }
    get() {
      return UNDEFINED_REFERENCE;
    }
    curry(definition) {
      var {
        args
      } = this;
      if (!args && isCurriedComponentDefinition(definition)) {
        return definition;
      } else if (!definition) {
        return null;
      } else {
        return new CurriedComponentDefinition(definition, args);
      }
    }
  }
  class DynamicTextContent extends UpdatingOpcode {
    constructor(node, reference, lastValue) {
      super();
      this.node = node;
      this.reference = reference;
      this.lastValue = lastValue;
      this.type = 'dynamic-text';
    }
    evaluate() {
      var value = this.reference.value();
      var {
        lastValue
      } = this;
      if (value === lastValue) return;
      var normalized;
      if (isEmpty(value)) {
        normalized = '';
      } else if (isString(value)) {
        normalized = value;
      } else {
        normalized = String(value);
      }
      if (normalized !== lastValue) {
        var textNode = this.node;
        textNode.nodeValue = this.lastValue = normalized;
      }
    }
  }
  class ContentTypeReference {
    constructor(inner) {
      this.inner = inner;
    }
    isConst() {
      return false;
    }
    value() {
      var value = this.inner.value();
      if (shouldCoerce(value)) {
        return 1
        /* String */;
      } else if (isComponentDefinition(value)) {
        return 0
        /* Component */;
      } else if (isSafeString(value)) {
        return 3
        /* SafeString */;
      } else if (isFragment(value)) {
        return 4
        /* Fragment */;
      } else if (isNode(value)) {
        return 5
        /* Node */;
      } else {
        return 1
        /* String */;
      }
    }
  }
  APPEND_OPCODES.add(43
  /* AppendHTML */, vm => {
    var reference = vm.stack.popJs();
    var rawValue = reference.value();
    var value = isEmpty(rawValue) ? '' : String(rawValue);
    vm.elements().appendDynamicHTML(value);
  });
  APPEND_OPCODES.add(44
  /* AppendSafeHTML */, vm => {
    var reference = vm.stack.popJs();
    var rawValue = reference.value().toHTML();
    var value = isEmpty(rawValue) ? '' : rawValue;
    vm.elements().appendDynamicHTML(value);
  });
  APPEND_OPCODES.add(47
  /* AppendText */, vm => {
    var reference = vm.stack.popJs();
    var rawValue = reference.value();
    var value = isEmpty(rawValue) ? '' : String(rawValue);
    var node = vm.elements().appendDynamicText(value);
    if (!reference.isConst()) {
      vm.updateWith(new DynamicTextContent(node, reference, value));
    }
  });
  APPEND_OPCODES.add(45
  /* AppendDocumentFragment */, vm => {
    var reference = vm.stack.popJs();
    var value = reference.value();
    vm.elements().appendDynamicFragment(value);
  });
  APPEND_OPCODES.add(46
  /* AppendNode */, vm => {
    var reference = vm.stack.popJs();
    var value = reference.value();
    vm.elements().appendDynamicNode(value);
  });
  APPEND_OPCODES.add(39
  /* ChildScope */, vm => vm.pushChildScope());
  APPEND_OPCODES.add(40
  /* PopScope */, vm => vm.popScope());
  APPEND_OPCODES.add(59
  /* PushDynamicScope */, vm => vm.pushDynamicScope());
  APPEND_OPCODES.add(60
  /* PopDynamicScope */, vm => vm.popDynamicScope());
  APPEND_OPCODES.add(29
  /* Constant */, (vm, {
    op1: other
  }) => {
    vm.stack.pushJs(vm[CONSTANTS].getValue((0, _util.decodeHandle)(other)));
  });
  APPEND_OPCODES.add(30
  /* Primitive */, (vm, {
    op1: primitive
  }) => {
    var stack = vm.stack;
    if ((0, _util.isNonPrimitiveHandle)(primitive)) {
      // it is a handle which does not already exist on the stack
      var value = vm[CONSTANTS].getValue((0, _util.decodeHandle)(primitive));
      stack.pushJs(value);
    } else {
      // is already an encoded immediate or primitive handle
      stack.pushRaw(primitive);
    }
  });
  APPEND_OPCODES.add(31
  /* PrimitiveReference */, vm => {
    var stack = vm.stack;
    stack.pushJs(PrimitiveReference.create(stack.pop()));
  });
  APPEND_OPCODES.add(32
  /* ReifyU32 */, vm => {
    var stack = vm.stack;
    stack.pushSmallInt(stack.peekJs().value());
  });
  APPEND_OPCODES.add(33
  /* Dup */, (vm, {
    op1: register,
    op2: offset
  }) => {
    var position = vm.fetchValue(register) - offset;
    vm.stack.dup(position);
  });
  APPEND_OPCODES.add(34
  /* Pop */, (vm, {
    op1: count
  }) => {
    vm.stack.pop(count);
  });
  APPEND_OPCODES.add(35
  /* Load */, (vm, {
    op1: register
  }) => {
    vm.load(register);
  });
  APPEND_OPCODES.add(36
  /* Fetch */, (vm, {
    op1: register
  }) => {
    vm.fetch(register);
  });
  APPEND_OPCODES.add(58
  /* BindDynamicScope */, (vm, {
    op1: _names
  }) => {
    var names = vm[CONSTANTS].getArray(_names);
    vm.bindDynamicScope(names);
  });
  APPEND_OPCODES.add(69
  /* Enter */, (vm, {
    op1: args
  }) => {
    vm.enter(args);
  });
  APPEND_OPCODES.add(70
  /* Exit */, vm => {
    vm.exit();
  });
  APPEND_OPCODES.add(63
  /* PushSymbolTable */, (vm, {
    op1: _table
  }) => {
    var stack = vm.stack;
    stack.pushJs(vm[CONSTANTS].getSerializable(_table));
  });
  APPEND_OPCODES.add(62
  /* PushBlockScope */, vm => {
    var stack = vm.stack;
    stack.pushJs(vm.scope());
  });
  APPEND_OPCODES.add(61
  /* CompileBlock */, vm => {
    var stack = vm.stack;
    var block = stack.pop();
    if (block) {
      stack.pushSmallInt(vm.compile(block));
    } else {
      stack.pushNull();
    }
  }, 'jit');
  APPEND_OPCODES.add(64
  /* InvokeYield */, vm => {
    var {
      stack
    } = vm;
    var handle = stack.pop();
    var scope = stack.popJs();
    var table = stack.popJs();
    var args = stack.pop();
    if (table === null) {
      // To balance the pop{Frame,Scope}
      vm.pushFrame();
      vm.pushScope(scope); // Could be null but it doesnt matter as it is immediatelly popped.

      return;
    }
    var invokingScope = scope; // If necessary, create a child scope

    {
      var locals = table.parameters;
      var localsCount = locals.length;
      if (localsCount > 0) {
        invokingScope = invokingScope.child();
        for (var i = 0; i < localsCount; i++) {
          invokingScope.bindSymbol(locals[i], args.at(i));
        }
      }
    }
    vm.pushFrame();
    vm.pushScope(invokingScope);
    vm.call(handle);
  });
  APPEND_OPCODES.add(65
  /* JumpIf */, (vm, {
    op1: target
  }) => {
    var reference = vm.stack.popJs();
    var value = Boolean(reference.value());
    if (reference.isConst()) {
      if (value === true) {
        vm.goto(target);
      }
    } else {
      var cache = new _reference.ReferenceCache(reference);
      if (value === true) {
        vm.goto(target);
      }
      vm.updateWith(new Assert(cache));
    }
  });
  APPEND_OPCODES.add(66
  /* JumpUnless */, (vm, {
    op1: target
  }) => {
    var reference = vm.stack.popJs();
    var value = Boolean(reference.value());
    if (reference.isConst()) {
      if (value === false) {
        vm.goto(target);
      }
    } else {
      var cache = new _reference.ReferenceCache(reference);
      if (value === false) {
        vm.goto(target);
      }
      vm.updateWith(new Assert(cache));
    }
  });
  APPEND_OPCODES.add(67
  /* JumpEq */, (vm, {
    op1: target,
    op2: comparison
  }) => {
    var other = vm.stack.peekSmallInt();
    if (other === comparison) {
      vm.goto(target);
    }
  });
  APPEND_OPCODES.add(68
  /* AssertSame */, vm => {
    var reference = vm.stack.peekJs();
    if (!reference.isConst()) {
      vm.updateWith(new Assert(new _reference.ReferenceCache(reference)));
    }
  });
  APPEND_OPCODES.add(71
  /* ToBoolean */, vm => {
    var {
      stack
    } = vm;
    var inner = stack.popJs();
    stack.pushJs(new ConditionalReference(inner));
  });
  class Assert extends UpdatingOpcode {
    constructor(cache) {
      super();
      this.cache = cache;
      this.type = 'assert';
    }
    evaluate(vm) {
      var {
        cache
      } = this;
      if ((0, _reference.isModified)(cache.revalidate())) {
        vm.throw();
      }
    }
  }
  class JumpIfNotModifiedOpcode extends UpdatingOpcode {
    constructor() {
      super(...arguments);
      this.type = 'jump-if-not-modified';
      this.tag = _validator.CONSTANT_TAG;
      this.lastRevision = _validator.INITIAL;
    }
    finalize(tag, target) {
      this.target = target;
      this.didModify(tag);
    }
    evaluate(vm) {
      var {
        tag,
        target,
        lastRevision
      } = this;
      if (!vm.alwaysRevalidate && (0, _validator.validateTag)(tag, lastRevision)) {
        (0, _validator.consumeTag)(tag);
        vm.goto(target);
      }
    }
    didModify(tag) {
      this.tag = tag;
      this.lastRevision = (0, _validator.valueForTag)(this.tag);
      (0, _validator.consumeTag)(tag);
    }
  }
  class BeginTrackFrameOpcode extends UpdatingOpcode {
    constructor(debugLabel) {
      super();
      this.debugLabel = debugLabel;
      this.type = 'begin-track-frame';
    }
    evaluate() {
      (0, _validator.beginTrackFrame)(this.debugLabel);
    }
  }
  class EndTrackFrameOpcode extends UpdatingOpcode {
    constructor(target) {
      super();
      this.target = target;
      this.type = 'end-track-frame';
    }
    evaluate() {
      var tag = (0, _validator.endTrackFrame)();
      this.target.didModify(tag);
    }
  }
  APPEND_OPCODES.add(41
  /* Text */, (vm, {
    op1: text
  }) => {
    vm.elements().appendText(vm[CONSTANTS].getValue(text));
  });
  APPEND_OPCODES.add(42
  /* Comment */, (vm, {
    op1: text
  }) => {
    vm.elements().appendComment(vm[CONSTANTS].getValue(text));
  });
  APPEND_OPCODES.add(48
  /* OpenElement */, (vm, {
    op1: tag
  }) => {
    vm.elements().openElement(vm[CONSTANTS].getValue(tag));
  });
  APPEND_OPCODES.add(49
  /* OpenDynamicElement */, vm => {
    var tagName = vm.stack.popJs().value();
    vm.elements().openElement(tagName);
  });
  APPEND_OPCODES.add(50
  /* PushRemoteElement */, vm => {
    var elementRef = vm.stack.popJs();
    var insertBeforeRef = vm.stack.popJs();
    var guidRef = vm.stack.popJs();
    var element = elementRef.value();
    var insertBefore = insertBeforeRef.value();
    var guid = guidRef.value();
    if (!elementRef.isConst()) {
      var cache = new _reference.ReferenceCache(elementRef);
      vm.updateWith(new Assert(cache));
    }
    if (insertBefore !== undefined && !insertBeforeRef.isConst()) {
      var _cache = new _reference.ReferenceCache(insertBeforeRef);
      vm.updateWith(new Assert(_cache));
    }
    var block = vm.elements().pushRemoteElement(element, guid, insertBefore);
    if (block) vm.associateDestroyable(block);
  });
  APPEND_OPCODES.add(56
  /* PopRemoteElement */, vm => {
    vm.elements().popRemoteElement();
  });
  APPEND_OPCODES.add(54
  /* FlushElement */, vm => {
    var operations = vm.fetchValue(_vm2.$t0);
    var modifiers = null;
    if (operations) {
      modifiers = operations.flush(vm);
      vm.loadValue(_vm2.$t0, null);
    }
    vm.elements().flushElement(modifiers);
  });
  APPEND_OPCODES.add(55
  /* CloseElement */, vm => {
    var modifiers = vm.elements().closeElement();
    if (modifiers) {
      modifiers.forEach(([manager, modifier]) => {
        vm.env.scheduleInstallModifier(modifier, manager);
        var d = manager.getDestroyable(modifier);
        if (d) {
          vm.associateDestroyable(d);
        }
      });
    }
  });
  APPEND_OPCODES.add(57
  /* Modifier */, (vm, {
    op1: handle
  }) => {
    var {
      manager,
      state
    } = vm.runtime.resolver.resolve(handle);
    var stack = vm.stack;
    var args = stack.popJs();
    var {
      constructing,
      updateOperations
    } = vm.elements();
    var dynamicScope = vm.dynamicScope();
    var modifier = manager.create(constructing, state, args, dynamicScope, updateOperations);
    var operations = vm.fetchValue(_vm2.$t0);
    operations.addModifier(manager, modifier);
    var tag = manager.getTag(modifier);
    if (tag !== null) {
      (0, _validator.consumeTag)(tag);
      vm.updateWith(new UpdateModifierOpcode(tag, manager, modifier));
    }
  });
  class UpdateModifierOpcode extends UpdatingOpcode {
    constructor(tag, manager, modifier) {
      super();
      this.tag = tag;
      this.manager = manager;
      this.modifier = modifier;
      this.type = 'update-modifier';
      this.lastUpdated = (0, _validator.valueForTag)(tag);
    }
    evaluate(vm) {
      var {
        manager,
        modifier,
        tag,
        lastUpdated
      } = this;
      (0, _validator.consumeTag)(tag);
      if (!(0, _validator.validateTag)(tag, lastUpdated)) {
        vm.env.scheduleUpdateModifier(modifier, manager);
        this.lastUpdated = (0, _validator.valueForTag)(tag);
      }
    }
  }
  APPEND_OPCODES.add(51
  /* StaticAttr */, (vm, {
    op1: _name,
    op2: _value,
    op3: _namespace
  }) => {
    var name = vm[CONSTANTS].getValue(_name);
    var value = vm[CONSTANTS].getValue(_value);
    var namespace = _namespace ? vm[CONSTANTS].getValue(_namespace) : null;
    vm.elements().setStaticAttribute(name, value, namespace);
  });
  APPEND_OPCODES.add(52
  /* DynamicAttr */, (vm, {
    op1: _name,
    op2: trusting,
    op3: _namespace
  }) => {
    var name = vm[CONSTANTS].getValue(_name);
    var reference = vm.stack.popJs();
    var value = reference.value();
    var namespace = _namespace ? vm[CONSTANTS].getValue(_namespace) : null;
    var attribute = vm.elements().setDynamicAttribute(name, value, !!trusting, namespace);
    if (!reference.isConst()) {
      vm.updateWith(new UpdateDynamicAttributeOpcode(reference, attribute));
    }
  });
  class UpdateDynamicAttributeOpcode extends UpdatingOpcode {
    constructor(reference, attribute) {
      super();
      this.reference = reference;
      this.attribute = attribute;
      this.type = 'patch-element';
      this.lastValue = reference.value();
    }
    evaluate(vm) {
      var {
        attribute,
        reference,
        lastValue
      } = this;
      var currentValue = reference.value();
      if (currentValue !== lastValue) {
        attribute.update(currentValue, vm.env);
        this.lastValue = currentValue;
      }
    }
  }
  /**
   * The VM creates a new ComponentInstance data structure for every component
   * invocation it encounters.
   *
   * Similar to how a ComponentDefinition contains state about all components of a
   * particular type, a ComponentInstance contains state specific to a particular
   * instance of a component type. It also contains a pointer back to its
   * component type's ComponentDefinition.
   */

  var COMPONENT_INSTANCE = (0, _util.symbol)('COMPONENT_INSTANCE');
  APPEND_OPCODES.add(77
  /* IsComponent */, vm => {
    var stack = vm.stack;
    var ref = stack.popJs();
    stack.pushJs(new ConditionalReference(ref, isCurriedComponentDefinition));
  });
  APPEND_OPCODES.add(78
  /* ContentType */, vm => {
    var stack = vm.stack;
    var ref = stack.peekJs();
    stack.pushJs(new ContentTypeReference(ref));
  });
  APPEND_OPCODES.add(79
  /* CurryComponent */, (vm, {
    op1: _meta
  }) => {
    var stack = vm.stack;
    var definition = stack.popJs();
    var capturedArgs = stack.popJs();
    var meta = vm[CONSTANTS].getValue((0, _util.decodeHandle)(_meta));
    var resolver = vm.runtime.resolver;
    vm.loadValue(_vm2.$v0, new CurryComponentReference(definition, resolver, meta, capturedArgs)); // expectStackChange(vm.stack, -args.length - 1, 'CurryComponent');
  });
  APPEND_OPCODES.add(80
  /* PushComponentDefinition */, (vm, {
    op1: handle
  }) => {
    var definition = vm.runtime.resolver.resolve(handle);
    var {
      manager
    } = definition;
    var capabilities = capabilityFlagsFrom(manager.getCapabilities(definition.state));
    var instance = {
      [COMPONENT_INSTANCE]: true,
      definition,
      manager,
      capabilities,
      state: null,
      handle: null,
      table: null,
      lookup: null
    };
    vm.stack.pushJs(instance);
  });
  APPEND_OPCODES.add(83
  /* ResolveDynamicComponent */, (vm, {
    op1: _meta
  }) => {
    var stack = vm.stack;
    var component = stack.popJs().value();
    var meta = vm[CONSTANTS].getValue((0, _util.decodeHandle)(_meta));
    vm.loadValue(_vm2.$t1, null); // Clear the temp register

    var definition;
    if (typeof component === 'string') {
      var resolvedDefinition = resolveComponent(vm.runtime.resolver, component, meta);
      definition = resolvedDefinition;
    } else if (isCurriedComponentDefinition(component)) {
      definition = component;
    } else {
      throw (0, _util.unreachable)();
    }
    stack.pushJs(definition);
  });
  APPEND_OPCODES.add(81
  /* PushDynamicComponentInstance */, vm => {
    var {
      stack
    } = vm;
    var definition = stack.pop();
    var capabilities, manager;
    if (isCurriedComponentDefinition(definition)) {
      manager = capabilities = null;
    } else {
      manager = definition.manager;
      capabilities = capabilityFlagsFrom(manager.getCapabilities(definition.state));
    }
    stack.pushJs({
      definition,
      capabilities,
      manager,
      state: null,
      handle: null,
      table: null
    });
  });
  APPEND_OPCODES.add(82
  /* PushCurriedComponent */, vm => {
    var stack = vm.stack;
    var component = stack.popJs().value();
    var definition;
    if (isCurriedComponentDefinition(component)) {
      definition = component;
    } else {
      throw (0, _util.unreachable)();
    }
    stack.pushJs(definition);
  });
  APPEND_OPCODES.add(84
  /* PushArgs */, (vm, {
    op1: _names,
    op2: _blockNames,
    op3: flags
  }) => {
    var stack = vm.stack;
    var names = vm[CONSTANTS].getArray(_names);
    var positionalCount = flags >> 4;
    var atNames = flags & 0b1000;
    var blockNames = flags & 0b0111 ? vm[CONSTANTS].getArray(_blockNames) : _util.EMPTY_ARRAY;
    vm[ARGS].setup(stack, names, blockNames, positionalCount, !!atNames);
    stack.pushJs(vm[ARGS]);
  });
  APPEND_OPCODES.add(85
  /* PushEmptyArgs */, vm => {
    var {
      stack
    } = vm;
    stack.pushJs(vm[ARGS].empty(stack));
  });
  APPEND_OPCODES.add(88
  /* CaptureArgs */, vm => {
    var stack = vm.stack;
    var args = stack.popJs();
    var capturedArgs = args.capture();
    stack.pushJs(capturedArgs);
  });
  APPEND_OPCODES.add(87
  /* PrepareArgs */, (vm, {
    op1: _state
  }) => {
    var stack = vm.stack;
    var instance = vm.fetchValue(_state);
    var args = stack.popJs();
    var {
      definition
    } = instance;
    if (isCurriedComponentDefinition(definition)) {
      definition = resolveCurriedComponentDefinition(instance, definition, args);
    }
    var {
      manager,
      state
    } = definition;
    var capabilities = instance.capabilities;
    if (!managerHasCapability(manager, capabilities, 4
    /* PrepareArgs */)) {
      stack.pushJs(args);
      return;
    }
    var blocks = args.blocks.values;
    var blockNames = args.blocks.names;
    var preparedArgs = manager.prepareArgs(state, args);
    if (preparedArgs) {
      args.clear();
      for (var i = 0; i < blocks.length; i++) {
        var block = blocks[i];
        if (typeof block === 'number') {
          stack.pushSmallInt(block);
        } else {
          stack.pushJs(block);
        }
      }
      var {
        positional,
        named
      } = preparedArgs;
      var positionalCount = positional.length;
      for (var _i = 0; _i < positionalCount; _i++) {
        stack.pushJs(positional[_i]);
      }
      var names = Object.keys(named);
      for (var _i2 = 0; _i2 < names.length; _i2++) {
        stack.pushJs(named[names[_i2]]);
      }
      args.setup(stack, names, blockNames, positionalCount, false);
    }
    stack.pushJs(args);
  });
  function resolveCurriedComponentDefinition(instance, definition, args) {
    var unwrappedDefinition = instance.definition = definition.unwrap(args);
    var {
      manager,
      state
    } = unwrappedDefinition;
    instance.manager = manager;
    instance.capabilities = capabilityFlagsFrom(manager.getCapabilities(state));
    return unwrappedDefinition;
  }
  APPEND_OPCODES.add(89
  /* CreateComponent */, (vm, {
    op1: flags,
    op2: _state
  }) => {
    var instance = vm.fetchValue(_state);
    var {
      definition,
      manager
    } = instance;
    var capabilities = instance.capabilities = capabilityFlagsFrom(manager.getCapabilities(definition.state));
    if (!managerHasCapability(manager, capabilities, 512
    /* CreateInstance */)) {
      throw new Error(`BUG`);
    }
    var dynamicScope = null;
    if (managerHasCapability(manager, capabilities, 64
    /* DynamicScope */)) {
      dynamicScope = vm.dynamicScope();
    }
    var hasDefaultBlock = flags & 1;
    var args = null;
    if (managerHasCapability(manager, capabilities, 8
    /* CreateArgs */)) {
      args = vm.stack.peekJs();
    }
    var self = null;
    if (managerHasCapability(manager, capabilities, 128
    /* CreateCaller */)) {
      self = vm.getSelf();
    }
    var state = manager.create(vm.env, definition.state, args, dynamicScope, self, !!hasDefaultBlock); // We want to reuse the `state` POJO here, because we know that the opcodes
    // only transition at exactly one place.

    instance.state = state;
    if (managerHasCapability(manager, capabilities, 256
    /* UpdateHook */)) {
      vm.updateWith(new UpdateComponentOpcode(state, manager, dynamicScope));
    }
  });
  APPEND_OPCODES.add(90
  /* RegisterComponentDestructor */, (vm, {
    op1: _state
  }) => {
    var {
      manager,
      state,
      capabilities
    } = vm.fetchValue(_state);
    var d = manager.getDestroyable(state);
    if (false /* DEBUG */ && !hasCapability(capabilities, 2048
    /* WillDestroy */) && d !== null && typeof 'willDestroy' in d) {
      throw new Error('BUG: Destructor has willDestroy, but the willDestroy capability was not enabled for this component. Pre-destruction hooks must be explicitly opted into');
    }
    if (d) vm.associateDestroyable(d);
  });
  APPEND_OPCODES.add(100
  /* BeginComponentTransaction */, (vm, {
    op1: _state
  }) => {
    var name;
    if (false /* DEBUG */) {
      var {
        definition,
        manager
      } = vm.fetchValue(_state);
      name = manager.getDebugName(definition.state);
    }
    vm.beginCacheGroup(name);
    vm.elements().pushSimpleBlock();
  });
  APPEND_OPCODES.add(91
  /* PutComponentOperations */, vm => {
    vm.loadValue(_vm2.$t0, new ComponentElementOperations());
  });
  APPEND_OPCODES.add(53
  /* ComponentAttr */, (vm, {
    op1: _name,
    op2: trusting,
    op3: _namespace
  }) => {
    var name = vm[CONSTANTS].getValue(_name);
    var reference = vm.stack.popJs();
    var namespace = _namespace ? vm[CONSTANTS].getValue(_namespace) : null;
    vm.fetchValue(_vm2.$t0).setAttribute(name, reference, !!trusting, namespace);
  });
  APPEND_OPCODES.add(108
  /* StaticComponentAttr */, (vm, {
    op1: _name,
    op2: _value,
    op3: _namespace
  }) => {
    var name = vm[CONSTANTS].getValue(_name);
    var value = vm[CONSTANTS].getValue(_value);
    var namespace = _namespace ? vm[CONSTANTS].getValue(_namespace) : null;
    vm.fetchValue(_vm2.$t0).setStaticAttribute(name, value, namespace);
  });
  class ComponentElementOperations {
    constructor() {
      this.attributes = (0, _util.dict)();
      this.classes = [];
      this.modifiers = [];
    }
    setAttribute(name, value, trusting, namespace) {
      var deferred = {
        value,
        namespace,
        trusting
      };
      if (name === 'class') {
        this.classes.push(value);
      }
      this.attributes[name] = deferred;
    }
    setStaticAttribute(name, value, namespace) {
      var deferred = {
        value,
        namespace
      };
      if (name === 'class') {
        this.classes.push(value);
      }
      this.attributes[name] = deferred;
    }
    addModifier(manager, state) {
      this.modifiers.push([manager, state]);
    }
    flush(vm) {
      var type;
      var attributes = this.attributes;
      for (var _name3 in this.attributes) {
        if (_name3 === 'type') {
          type = attributes[_name3];
          continue;
        }
        var attr = this.attributes[_name3];
        if (_name3 === 'class') {
          setDeferredAttr(vm, 'class', mergeClasses(this.classes), attr.namespace, attr.trusting);
        } else {
          setDeferredAttr(vm, _name3, attr.value, attr.namespace, attr.trusting);
        }
      }
      if (type !== undefined) {
        setDeferredAttr(vm, 'type', type.value, type.namespace, type.trusting);
      }
      return this.modifiers;
    }
  }
  function mergeClasses(classes) {
    if (classes.length === 0) {
      return '';
    }
    if (classes.length === 1) {
      return classes[0];
    }
    if (allStringClasses(classes)) {
      return classes.join(' ');
    }
    return makeClassList(classes);
  }
  function makeClassList(classes) {
    for (var i = 0; i < classes.length; i++) {
      var value = classes[i];
      if (typeof value === 'string') {
        classes[i] = PrimitiveReference.create(value);
      }
    }
    return new ClassListReference(classes);
  }
  function allStringClasses(classes) {
    for (var i = 0; i < classes.length; i++) {
      if (typeof classes[i] !== 'string') {
        return false;
      }
    }
    return true;
  }
  function setDeferredAttr(vm, name, value, namespace, trusting = false) {
    if (typeof value === 'string') {
      vm.elements().setStaticAttribute(name, value, namespace);
    } else {
      var attribute = vm.elements().setDynamicAttribute(name, value.value(), trusting, namespace);
      if (!value.isConst()) {
        vm.updateWith(new UpdateDynamicAttributeOpcode(value, attribute));
      }
    }
  }
  APPEND_OPCODES.add(102
  /* DidCreateElement */, (vm, {
    op1: _state
  }) => {
    var {
      definition,
      state
    } = vm.fetchValue(_state);
    var {
      manager
    } = definition;
    var operations = vm.fetchValue(_vm2.$t0);
    manager.didCreateElement(state, vm.elements().constructing, operations);
  });
  APPEND_OPCODES.add(92
  /* GetComponentSelf */, (vm, {
    op1: _state
  }) => {
    var {
      definition,
      state
    } = vm.fetchValue(_state);
    var {
      manager
    } = definition;
    vm.stack.pushJs(manager.getSelf(state));
  });
  APPEND_OPCODES.add(93
  /* GetComponentTagName */, (vm, {
    op1: _state
  }) => {
    var {
      definition,
      state
    } = vm.fetchValue(_state);
    var {
      manager
    } = definition;
    var tagName = manager.getTagName(state); // User provided value from JS, so we don't bother to encode

    vm.stack.pushJs(tagName);
  }); // Dynamic Invocation Only

  APPEND_OPCODES.add(95
  /* GetJitComponentLayout */, (vm, {
    op1: _state
  }) => {
    var instance = vm.fetchValue(_state);
    var manager = instance.manager;
    var {
      definition
    } = instance;
    var {
      stack
    } = vm;
    var {
      capabilities
    } = instance; // let invoke: { handle: number; symbolTable: ProgramSymbolTable };

    var layout;
    if (hasStaticLayoutCapability(capabilities, manager)) {
      layout = manager.getJitStaticLayout(definition.state, vm.runtime.resolver);
    } else if (hasDynamicLayoutCapability(capabilities, manager)) {
      var template = (0, _util.unwrapTemplate)(manager.getJitDynamicLayout(instance.state, vm.runtime.resolver));
      if (hasCapability(capabilities, 1024
      /* Wrapped */)) {
        layout = template.asWrappedLayout();
      } else {
        layout = template.asLayout();
      }
    } else {
      throw (0, _util.unreachable)();
    }
    var handle = layout.compile(vm.context);
    stack.pushJs(layout.symbolTable);
    if (false /* DEBUG */ && (0, _util.isErrHandle)(handle)) {
      stack.pushJs(handle);
    } else {
      stack.pushSmallInt(handle);
    }
  }, 'jit'); // Dynamic Invocation Only

  APPEND_OPCODES.add(94
  /* GetAotComponentLayout */, (vm, {
    op1: _state
  }) => {
    var instance = vm.fetchValue(_state);
    var {
      manager,
      definition
    } = instance;
    var {
      stack
    } = vm;
    var {
      state: instanceState,
      capabilities
    } = instance;
    var {
      state: definitionState
    } = definition;
    var invoke;
    if (hasStaticLayoutCapability(capabilities, manager)) {
      invoke = manager.getAotStaticLayout(definitionState, vm.runtime.resolver);
    } else if (hasDynamicLayoutCapability(capabilities, manager)) {
      invoke = manager.getAotDynamicLayout(instanceState, vm.runtime.resolver);
    } else {
      throw (0, _util.unreachable)();
    }
    stack.pushJs(invoke.symbolTable);
    if (false /* DEBUG */ && (0, _util.isErrHandle)(invoke.handle)) {
      stack.pushJs(invoke.handle);
    } else {
      stack.pushSmallInt(invoke.handle);
    }
  }); // These types are absurd here

  function hasStaticLayoutCapability(capabilities, _manager) {
    return managerHasCapability(_manager, capabilities, 1
    /* DynamicLayout */) === false;
  }
  function hasDynamicLayoutCapability(capabilities, _manager) {
    return managerHasCapability(_manager, capabilities, 1
    /* DynamicLayout */) === true;
  }
  APPEND_OPCODES.add(76
  /* Main */, (vm, {
    op1: register
  }) => {
    var definition = vm.stack.popJs();
    var invocation = vm.stack.popJs();
    var {
      manager
    } = definition;
    var capabilities = capabilityFlagsFrom(manager.getCapabilities(definition.state));
    var state = {
      [COMPONENT_INSTANCE]: true,
      definition,
      manager,
      capabilities,
      state: null,
      handle: invocation.handle,
      table: invocation.symbolTable,
      lookup: null
    };
    vm.loadValue(register, state);
  });
  APPEND_OPCODES.add(98
  /* PopulateLayout */, (vm, {
    op1: _state
  }) => {
    var {
      stack
    } = vm; // In DEBUG handles could be ErrHandle objects

    var handle = false /* DEBUG */ ? stack.pop() : stack.popSmallInt();
    var table = stack.popJs();
    var state = vm.fetchValue(_state);
    state.handle = handle;
    state.table = table;
  });
  APPEND_OPCODES.add(38
  /* VirtualRootScope */, (vm, {
    op1: _state
  }) => {
    var {
      symbols
    } = vm.fetchValue(_state).table;
    vm.pushRootScope(symbols.length + 1);
  });
  APPEND_OPCODES.add(97
  /* SetupForEval */, (vm, {
    op1: _state
  }) => {
    var state = vm.fetchValue(_state);
    if (state.table.hasEval) {
      var lookup = state.lookup = (0, _util.dict)();
      vm.scope().bindEvalScope(lookup);
    }
  });
  APPEND_OPCODES.add(17
  /* SetNamedVariables */, (vm, {
    op1: _state
  }) => {
    var state = vm.fetchValue(_state);
    var scope = vm.scope();
    var args = vm.stack.peekJs();
    var callerNames = args.named.atNames;
    for (var i = callerNames.length - 1; i >= 0; i--) {
      var atName = callerNames[i];
      var symbol$$1 = state.table.symbols.indexOf(callerNames[i]);
      var value = args.named.get(atName, true);
      if (symbol$$1 !== -1) scope.bindSymbol(symbol$$1 + 1, value);
      if (state.lookup) state.lookup[atName] = value;
    }
  });
  function bindBlock(symbolName, blockName, state, blocks, vm) {
    var symbol$$1 = state.table.symbols.indexOf(symbolName);
    var block = blocks.get(blockName);
    if (symbol$$1 !== -1) vm.scope().bindBlock(symbol$$1 + 1, block);
    if (state.lookup) state.lookup[symbolName] = block;
  }
  APPEND_OPCODES.add(18
  /* SetBlocks */, (vm, {
    op1: _state
  }) => {
    var state = vm.fetchValue(_state);
    var {
      blocks
    } = vm.stack.peekJs();
    for (var i = 0; i < blocks.names.length; i++) {
      bindBlock(blocks.symbolNames[i], blocks.names[i], state, blocks, vm);
    }
  }); // Dynamic Invocation Only

  APPEND_OPCODES.add(99
  /* InvokeComponentLayout */, (vm, {
    op1: _state
  }) => {
    var state = vm.fetchValue(_state);
    vm.call(state.handle);
  });
  APPEND_OPCODES.add(103
  /* DidRenderLayout */, (vm, {
    op1: _state
  }) => {
    var {
      manager,
      state,
      capabilities
    } = vm.fetchValue(_state);
    var bounds = vm.elements().popBlock();
    if (!managerHasCapability(manager, capabilities, 512
    /* CreateInstance */)) {
      throw new Error(`BUG`);
    }
    var mgr = manager;
    mgr.didRenderLayout(state, bounds);
    vm.env.didCreate(state, manager);
    vm.updateWith(new DidUpdateLayoutOpcode(manager, state, bounds));
  });
  APPEND_OPCODES.add(101
  /* CommitComponentTransaction */, vm => {
    vm.commitCacheGroup();
  });
  class UpdateComponentOpcode extends UpdatingOpcode {
    constructor(component, manager, dynamicScope) {
      super();
      this.component = component;
      this.manager = manager;
      this.dynamicScope = dynamicScope;
      this.type = 'update-component';
    }
    evaluate(_vm) {
      var {
        component,
        manager,
        dynamicScope
      } = this;
      manager.update(component, dynamicScope);
    }
  }
  class DidUpdateLayoutOpcode extends UpdatingOpcode {
    constructor(manager, component, bounds) {
      super();
      this.manager = manager;
      this.component = component;
      this.bounds = bounds;
      this.type = 'did-update-layout';
    }
    evaluate(vm) {
      var {
        manager,
        component,
        bounds
      } = this;
      manager.didUpdateLayout(component, bounds);
      vm.env.didUpdate(component, manager);
    }
  }
  function debugCallback(context, get) {
    console.info('Use `context`, and `get(<path>)` to debug this template.'); // for example...
    // eslint-disable-next-line no-unused-expressions

    context === get('this'); // eslint-disable-next-line no-debugger

    debugger;
  }
  var callback = debugCallback; // For testing purposes

  function setDebuggerCallback(cb) {
    callback = cb;
  }
  function resetDebuggerCallback() {
    callback = debugCallback;
  }
  class ScopeInspector {
    constructor(scope, symbols, evalInfo) {
      this.scope = scope;
      this.locals = (0, _util.dict)();
      for (var i = 0; i < evalInfo.length; i++) {
        var slot = evalInfo[i];
        var _name4 = symbols[slot - 1];
        var ref = scope.getSymbol(slot);
        this.locals[_name4] = ref;
      }
    }
    get(path) {
      var {
        scope,
        locals
      } = this;
      var parts = path.split('.');
      var [head, ...tail] = path.split('.');
      var evalScope = scope.getEvalScope();
      var ref;
      if (head === 'this') {
        ref = scope.getSelf();
      } else if (locals[head]) {
        ref = locals[head];
      } else if (head.indexOf('@') === 0 && evalScope[head]) {
        ref = evalScope[head];
      } else {
        ref = this.scope.getSelf();
        tail = parts;
      }
      return tail.reduce((r, part) => r.get(part), ref);
    }
  }
  APPEND_OPCODES.add(106
  /* Debugger */, (vm, {
    op1: _symbols,
    op2: _evalInfo
  }) => {
    var symbols = vm[CONSTANTS].getArray(_symbols);
    var evalInfo = vm[CONSTANTS].getValue((0, _util.decodeHandle)(_evalInfo));
    var inspector = new ScopeInspector(vm.scope(), symbols, evalInfo);
    callback(vm.getSelf().value(), path => inspector.get(path).value());
  });
  APPEND_OPCODES.add(104
  /* InvokePartial */, (vm, {
    op1: _meta,
    op2: _symbols,
    op3: _evalInfo
  }) => {
    var {
      [CONSTANTS]: constants$$1,
      stack
    } = vm;
    var name = stack.popJs().value();
    var meta = constants$$1.getValue((0, _util.decodeHandle)(_meta));
    var outerSymbols = constants$$1.getArray(_symbols);
    var evalInfo = constants$$1.getValue((0, _util.decodeHandle)(_evalInfo));
    var handle = vm.runtime.resolver.lookupPartial(name, meta);
    var definition = vm.runtime.resolver.resolve(handle);
    var {
      symbolTable,
      handle: vmHandle
    } = definition.getPartial(vm.context);
    {
      var partialSymbols = symbolTable.symbols;
      var outerScope = vm.scope();
      var partialScope = vm.pushRootScope(partialSymbols.length);
      var evalScope = outerScope.getEvalScope();
      partialScope.bindEvalScope(evalScope);
      partialScope.bindSelf(outerScope.getSelf());
      var locals = Object.create(outerScope.getPartialMap());
      for (var i = 0; i < evalInfo.length; i++) {
        var slot = evalInfo[i];
        var _name5 = outerSymbols[slot - 1];
        var ref = outerScope.getSymbol(slot);
        locals[_name5] = ref;
      }
      if (evalScope) {
        for (var _i3 = 0; _i3 < partialSymbols.length; _i3++) {
          var _name6 = partialSymbols[_i3];
          var symbol$$1 = _i3 + 1;
          var value = evalScope[_name6];
          if (value !== undefined) partialScope.bind(symbol$$1, value);
        }
      }
      partialScope.bindPartialMap(locals);
      vm.pushFrame(); // sp += 2

      vm.call((0, _util.unwrapHandle)(vmHandle));
    }
  }, 'jit');
  APPEND_OPCODES.add(74
  /* PutIterator */, vm => {
    var stack = vm.stack;
    var listRef = stack.popJs();
    var keyRef = stack.popJs();
    var keyValue = keyRef.value();
    var key = keyValue === null ? '@identity' : String(keyValue);
    var iterableRef = new _reference.IterableReference(listRef, key); // Push the first time to push the iterator onto the stack for iteration

    stack.pushJs(iterableRef); // Push the second time to push it as a reference for presence in general
    // (e.g whether or not it is empty). This reference will be used to skip
    // iteration entirely.

    stack.pushJs(iterableRef);
  });
  APPEND_OPCODES.add(72
  /* EnterList */, (vm, {
    op1: relativeStart
  }) => {
    vm.enterList(relativeStart);
  });
  APPEND_OPCODES.add(73
  /* ExitList */, vm => {
    vm.exitList();
  });
  APPEND_OPCODES.add(75
  /* Iterate */, (vm, {
    op1: breaks
  }) => {
    var stack = vm.stack;
    var iterable = stack.peekJs();
    var item = iterable.next();
    if (item) {
      var opcode = vm.enterItem(iterable, item);
      vm.registerItem(opcode);
    } else {
      vm.goto(breaks);
    }
  });
  /** @internal */

  var DEFAULT_CAPABILITIES = _exports.DEFAULT_CAPABILITIES = {
    dynamicLayout: true,
    dynamicTag: true,
    prepareArgs: true,
    createArgs: true,
    attributeHook: false,
    elementHook: false,
    dynamicScope: true,
    createCaller: false,
    updateHook: true,
    createInstance: true,
    wrapped: false,
    willDestroy: false
  };
  var MINIMAL_CAPABILITIES = _exports.MINIMAL_CAPABILITIES = {
    dynamicLayout: false,
    dynamicTag: false,
    prepareArgs: false,
    createArgs: false,
    attributeHook: false,
    elementHook: false,
    dynamicScope: false,
    createCaller: false,
    updateHook: false,
    createInstance: false,
    wrapped: false,
    willDestroy: false
  };
  class SimpleComponentManager {
    getCapabilities(_state) {
      return MINIMAL_CAPABILITIES;
    }
    getDebugName() {
      return '';
    }
    prepareArgs(_state, _args) {
      throw new Error(`Unimplemented prepareArgs in SimpleComponentManager`);
    }
    create(_env, _state, _args, _dynamicScope, _caller, _hasDefaultBlock) {
      throw new Error(`Unimplemented create in SimpleComponentManager`);
    }
    getSelf(_state) {
      return UNDEFINED_REFERENCE;
    }
    didRenderLayout(_state, _bounds) {
      throw new Error(`Unimplemented didRenderLayout in SimpleComponentManager`);
    }
    didCreate(_state) {
      throw new Error(`Unimplemented didCreate in SimpleComponentManager`);
    }
    update(_state, _dynamicScope) {
      throw new Error(`Unimplemented update in SimpleComponentManager`);
    }
    didUpdateLayout(_state, _bounds) {
      throw new Error(`Unimplemented didUpdateLayout in SimpleComponentManager`);
    }
    didUpdate(_state) {
      throw new Error(`Unimplemented didUpdate in SimpleComponentManager`);
    }
    getDestroyable(_state) {
      return null;
    }
  }
  _exports.SimpleComponentManager = SimpleComponentManager;
  var TEMPLATE_ONLY_COMPONENT = _exports.TEMPLATE_ONLY_COMPONENT = {
    state: null,
    manager: new SimpleComponentManager()
  }; // http://www.w3.org/TR/html/syntax.html#html-integration-point

  var SVG_INTEGRATION_POINTS = {
    foreignObject: 1,
    desc: 1,
    title: 1
  }; // http://www.w3.org/TR/html/syntax.html#adjust-svg-attributes
  // TODO: Adjust SVG attributes
  // http://www.w3.org/TR/html/syntax.html#parsing-main-inforeign
  // TODO: Adjust SVG elements
  // http://www.w3.org/TR/html/syntax.html#parsing-main-inforeign

  var BLACKLIST_TABLE = Object.create(null);
  class DOMOperations {
    constructor(document) {
      this.document = document;
      this.setupUselessElement();
    } // split into seperate method so that NodeDOMTreeConstruction
    // can override it.

    setupUselessElement() {
      this.uselessElement = this.document.createElement('div');
    }
    createElement(tag, context) {
      var isElementInSVGNamespace, isHTMLIntegrationPoint;
      if (context) {
        isElementInSVGNamespace = context.namespaceURI === "http://www.w3.org/2000/svg"
        /* SVG */ || tag === 'svg';
        isHTMLIntegrationPoint = !!SVG_INTEGRATION_POINTS[context.tagName];
      } else {
        isElementInSVGNamespace = tag === 'svg';
        isHTMLIntegrationPoint = false;
      }
      if (isElementInSVGNamespace && !isHTMLIntegrationPoint) {
        // FIXME: This does not properly handle <font> with color, face, or
        // size attributes, which is also disallowed by the spec. We should fix
        // this.
        if (BLACKLIST_TABLE[tag]) {
          throw new Error(`Cannot create a ${tag} inside an SVG context`);
        }
        return this.document.createElementNS("http://www.w3.org/2000/svg"
        /* SVG */, tag);
      } else {
        return this.document.createElement(tag);
      }
    }
    insertBefore(parent, node, reference) {
      parent.insertBefore(node, reference);
    }
    insertHTMLBefore(parent, nextSibling, html) {
      if (html === '') {
        var comment = this.createComment('');
        parent.insertBefore(comment, nextSibling);
        return new ConcreteBounds(parent, comment, comment);
      }
      var prev = nextSibling ? nextSibling.previousSibling : parent.lastChild;
      var last;
      if (nextSibling === null) {
        parent.insertAdjacentHTML("beforeend"
        /* beforeend */, html);
        last = parent.lastChild;
      } else if (nextSibling instanceof HTMLElement) {
        nextSibling.insertAdjacentHTML('beforebegin', html);
        last = nextSibling.previousSibling;
      } else {
        // Non-element nodes do not support insertAdjacentHTML, so add an
        // element and call it on that element. Then remove the element.
        //
        // This also protects Edge, IE and Firefox w/o the inspector open
        // from merging adjacent text nodes. See ./compat/text-node-merging-fix.ts
        var {
          uselessElement
        } = this;
        parent.insertBefore(uselessElement, nextSibling);
        uselessElement.insertAdjacentHTML("beforebegin"
        /* beforebegin */, html);
        last = uselessElement.previousSibling;
        parent.removeChild(uselessElement);
      }
      var first = prev ? prev.nextSibling : parent.firstChild;
      return new ConcreteBounds(parent, first, last);
    }
    createTextNode(text) {
      return this.document.createTextNode(text);
    }
    createComment(data) {
      return this.document.createComment(data);
    }
  }
  function moveNodesBefore(source, target, nextSibling) {
    var first = source.firstChild;
    var last = first;
    var current = first;
    while (current) {
      var next = current.nextSibling;
      target.insertBefore(current, nextSibling);
      last = current;
      current = next;
    }
    return new ConcreteBounds(target, first, last);
  }
  var SVG_NAMESPACE = "http://www.w3.org/2000/svg"
  /* SVG */; // Patch:    insertAdjacentHTML on SVG Fix
  // Browsers: Safari, IE, Edge, Firefox ~33-34
  // Reason:   insertAdjacentHTML does not exist on SVG elements in Safari. It is
  //           present but throws an exception on IE and Edge. Old versions of
  //           Firefox create nodes in the incorrect namespace.
  // Fix:      Since IE and Edge silently fail to create SVG nodes using
  //           innerHTML, and because Firefox may create nodes in the incorrect
  //           namespace using innerHTML on SVG elements, an HTML-string wrapping
  //           approach is used. A pre/post SVG tag is added to the string, then
  //           that whole string is added to a div. The created nodes are plucked
  //           out and applied to the target location on DOM.

  function applySVGInnerHTMLFix(document, DOMClass, svgNamespace) {
    if (!document) return DOMClass;
    if (!shouldApplyFix(document, svgNamespace)) {
      return DOMClass;
    }
    var div = document.createElement('div');
    return class DOMChangesWithSVGInnerHTMLFix extends DOMClass {
      insertHTMLBefore(parent, nextSibling, html) {
        if (html === '') {
          return super.insertHTMLBefore(parent, nextSibling, html);
        }
        if (parent.namespaceURI !== svgNamespace) {
          return super.insertHTMLBefore(parent, nextSibling, html);
        }
        return fixSVG(parent, div, html, nextSibling);
      }
    };
  }
  function fixSVG(parent, div, html, reference) {
    var source; // This is important, because decendants of the <foreignObject> integration
    // point are parsed in the HTML namespace

    if (parent.tagName.toUpperCase() === 'FOREIGNOBJECT') {
      // IE, Edge: also do not correctly support using `innerHTML` on SVG
      // namespaced elements. So here a wrapper is used.
      var wrappedHtml = '<svg><foreignObject>' + html + '</foreignObject></svg>';
      (0, _util.clearElement)(div);
      div.insertAdjacentHTML("afterbegin"
      /* afterbegin */, wrappedHtml);
      source = div.firstChild.firstChild;
    } else {
      // IE, Edge: also do not correctly support using `innerHTML` on SVG
      // namespaced elements. So here a wrapper is used.
      var _wrappedHtml = '<svg>' + html + '</svg>';
      (0, _util.clearElement)(div);
      div.insertAdjacentHTML("afterbegin"
      /* afterbegin */, _wrappedHtml);
      source = div.firstChild;
    }
    return moveNodesBefore(source, parent, reference);
  }
  function shouldApplyFix(document, svgNamespace) {
    var svg = document.createElementNS(svgNamespace, 'svg');
    try {
      svg.insertAdjacentHTML("beforeend"
      /* beforeend */, '<circle></circle>');
    } catch (e) {// IE, Edge: Will throw, insertAdjacentHTML is unsupported on SVG
      // Safari: Will throw, insertAdjacentHTML is not present on SVG
    } finally {
      // FF: Old versions will create a node in the wrong namespace
      if (svg.childNodes.length === 1 && svg.firstChild.namespaceURI === SVG_NAMESPACE) {
        // The test worked as expected, no fix required
        return false;
      }
      return true;
    }
  } // Patch:    Adjacent text node merging fix
  // Browsers: IE, Edge, Firefox w/o inspector open
  // Reason:   These browsers will merge adjacent text nodes. For exmaple given
  //           <div>Hello</div> with div.insertAdjacentHTML(' world') browsers
  //           with proper behavior will populate div.childNodes with two items.
  //           These browsers will populate it with one merged node instead.
  // Fix:      Add these nodes to a wrapper element, then iterate the childNodes
  //           of that wrapper and move the nodes to their target location. Note
  //           that potential SVG bugs will have been handled before this fix.
  //           Note that this fix must only apply to the previous text node, as
  //           the base implementation of `insertHTMLBefore` already handles
  //           following text nodes correctly.

  function applyTextNodeMergingFix(document, DOMClass) {
    if (!document) return DOMClass;
    if (!shouldApplyFix$1(document)) {
      return DOMClass;
    }
    return class DOMChangesWithTextNodeMergingFix extends DOMClass {
      constructor(document) {
        super(document);
        this.uselessComment = document.createComment('');
      }
      insertHTMLBefore(parent, nextSibling, html) {
        if (html === '') {
          return super.insertHTMLBefore(parent, nextSibling, html);
        }
        var didSetUselessComment = false;
        var nextPrevious = nextSibling ? nextSibling.previousSibling : parent.lastChild;
        if (nextPrevious && nextPrevious instanceof Text) {
          didSetUselessComment = true;
          parent.insertBefore(this.uselessComment, nextSibling);
        }
        var bounds = super.insertHTMLBefore(parent, nextSibling, html);
        if (didSetUselessComment) {
          parent.removeChild(this.uselessComment);
        }
        return bounds;
      }
    };
  }
  function shouldApplyFix$1(document) {
    var mergingTextDiv = document.createElement('div');
    mergingTextDiv.appendChild(document.createTextNode('first'));
    mergingTextDiv.insertAdjacentHTML("beforeend"
    /* beforeend */, 'second');
    if (mergingTextDiv.childNodes.length === 2) {
      // It worked as expected, no fix required
      return false;
    }
    return true;
  }
  ['b', 'big', 'blockquote', 'body', 'br', 'center', 'code', 'dd', 'div', 'dl', 'dt', 'em', 'embed', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'hr', 'i', 'img', 'li', 'listing', 'main', 'meta', 'nobr', 'ol', 'p', 'pre', 'ruby', 's', 'small', 'span', 'strong', 'strike', 'sub', 'sup', 'table', 'tt', 'u', 'ul', 'var'].forEach(tag => BLACKLIST_TABLE[tag] = 1);
  var WHITESPACE = /[\t-\r \xA0\u1680\u180E\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]/;
  var doc = typeof document === 'undefined' ? null : document;
  function isWhitespace(string) {
    return WHITESPACE.test(string);
  }
  var DOM;
  (function (DOM) {
    class TreeConstruction extends DOMOperations {
      createElementNS(namespace, tag) {
        return this.document.createElementNS(namespace, tag);
      }
      setAttribute(element, name, value, namespace = null) {
        if (namespace) {
          element.setAttributeNS(namespace, name, value);
        } else {
          element.setAttribute(name, value);
        }
      }
    }
    DOM.TreeConstruction = TreeConstruction;
    var appliedTreeContruction = TreeConstruction;
    appliedTreeContruction = applyTextNodeMergingFix(doc, appliedTreeContruction);
    appliedTreeContruction = applySVGInnerHTMLFix(doc, appliedTreeContruction, "http://www.w3.org/2000/svg"
    /* SVG */);
    DOM.DOMTreeConstruction = appliedTreeContruction;
  })(DOM || (DOM = {}));
  class DOMChangesImpl extends DOMOperations {
    constructor(document) {
      super(document);
      this.document = document;
      this.namespace = null;
    }
    setAttribute(element, name, value) {
      element.setAttribute(name, value);
    }
    removeAttribute(element, name) {
      element.removeAttribute(name);
    }
    insertAfter(element, node, reference) {
      this.insertBefore(element, node, reference.nextSibling);
    }
  }
  _exports.IDOMChanges = DOMChangesImpl;
  var helper = DOMChangesImpl;
  helper = applyTextNodeMergingFix(doc, helper);
  helper = applySVGInnerHTMLFix(doc, helper, "http://www.w3.org/2000/svg"
  /* SVG */);
  var helper$1 = _exports.DOMChanges = helper;
  var DOMTreeConstruction = _exports.DOMTreeConstruction = DOM.DOMTreeConstruction;
  var _a$2;
  var TRANSACTION = (0, _util.symbol)('TRANSACTION');
  class TransactionImpl {
    constructor() {
      this.scheduledInstallManagers = [];
      this.scheduledInstallModifiers = [];
      this.scheduledUpdateModifierManagers = [];
      this.scheduledUpdateModifiers = [];
      this.createdComponents = [];
      this.createdManagers = [];
      this.updatedComponents = [];
      this.updatedManagers = [];
    }
    didCreate(component, manager) {
      this.createdComponents.push(component);
      this.createdManagers.push(manager);
    }
    didUpdate(component, manager) {
      this.updatedComponents.push(component);
      this.updatedManagers.push(manager);
    }
    scheduleInstallModifier(modifier, manager) {
      this.scheduledInstallModifiers.push(modifier);
      this.scheduledInstallManagers.push(manager);
    }
    scheduleUpdateModifier(modifier, manager) {
      this.scheduledUpdateModifiers.push(modifier);
      this.scheduledUpdateModifierManagers.push(manager);
    }
    commit() {
      var {
        createdComponents,
        createdManagers
      } = this;
      for (var i = 0; i < createdComponents.length; i++) {
        var component = createdComponents[i];
        var _manager2 = createdManagers[i];
        _manager2.didCreate(component);
      }
      var {
        updatedComponents,
        updatedManagers
      } = this;
      for (var _i4 = 0; _i4 < updatedComponents.length; _i4++) {
        var _component = updatedComponents[_i4];
        var _manager3 = updatedManagers[_i4];
        _manager3.didUpdate(_component);
      }
      var {
        scheduledInstallManagers,
        scheduledInstallModifiers
      } = this;
      var manager, modifier;
      for (var _i5 = 0; _i5 < scheduledInstallManagers.length; _i5++) {
        modifier = scheduledInstallModifiers[_i5];
        manager = scheduledInstallManagers[_i5];
        var modifierTag = manager.getTag(modifier);
        if (modifierTag !== null) {
          var tag = (0, _validator.track)(
          // eslint-disable-next-line no-loop-func
          () => manager.install(modifier), false /* DEBUG */ && `- While rendering:\n\n  (instance of a \`${manager.getDebugName(modifier)}\` modifier)`);
          (0, _validator.updateTag)(modifierTag, tag);
        } else {
          manager.install(modifier);
        }
      }
      var {
        scheduledUpdateModifierManagers,
        scheduledUpdateModifiers
      } = this;
      for (var _i6 = 0; _i6 < scheduledUpdateModifierManagers.length; _i6++) {
        modifier = scheduledUpdateModifiers[_i6];
        manager = scheduledUpdateModifierManagers[_i6];
        var _modifierTag = manager.getTag(modifier);
        if (_modifierTag !== null) {
          var _tag = (0, _validator.track)(
          // eslint-disable-next-line no-loop-func
          () => manager.update(modifier), false /* DEBUG */ && `While rendering an instance of a \`${(0, _util.debugToString)(modifier)}\` modifier`);
          (0, _validator.updateTag)(_modifierTag, _tag);
        } else {
          manager.update(modifier);
        }
      }
    }
  }
  class EnvironmentImpl {
    constructor(options, delegate) {
      this.delegate = delegate;
      this[_a$2] = null; // Delegate methods and values

      this.extra = this.delegate.extra;
      this.isInteractive = this.delegate.isInteractive;
      if (options.appendOperations) {
        this.appendOperations = options.appendOperations;
        this.updateOperations = options.updateOperations;
      } else if (options.document) {
        this.appendOperations = new DOMTreeConstruction(options.document);
        this.updateOperations = new DOMChangesImpl(options.document);
      } else if (false /* DEBUG */) {
        throw new Error('you must pass document or appendOperations to a new runtime');
      }
    }
    getAppendOperations() {
      return this.appendOperations;
    }
    getDOM() {
      return this.updateOperations;
    }
    begin() {
      this.delegate.onTransactionBegin();
      this[TRANSACTION] = new TransactionImpl();
    }
    get transaction() {
      return this[TRANSACTION];
    }
    didCreate(component, manager) {
      this.transaction.didCreate(component, manager);
    }
    didUpdate(component, manager) {
      this.transaction.didUpdate(component, manager);
    }
    scheduleInstallModifier(modifier, manager) {
      if (this.isInteractive) {
        this.transaction.scheduleInstallModifier(modifier, manager);
      }
    }
    scheduleUpdateModifier(modifier, manager) {
      if (this.isInteractive) {
        this.transaction.scheduleUpdateModifier(modifier, manager);
      }
    }
    commit() {
      var transaction = this.transaction;
      this[TRANSACTION] = null;
      transaction.commit();
      this.delegate.onTransactionCommit();
    }
  }
  _exports.EnvironmentImpl = EnvironmentImpl;
  _a$2 = TRANSACTION;
  class DefaultRuntimeResolver {
    constructor(inner) {
      this.inner = inner;
    }
    lookupComponent(name, referrer) {
      if (this.inner.lookupComponent) {
        var component = this.inner.lookupComponent(name, referrer);
        if (component === undefined) {
          throw new Error(`Unexpected component ${name} (from ${referrer}) (lookupComponent returned undefined)`);
        }
        return component;
      } else {
        throw new Error('lookupComponent not implemented on RuntimeResolver.');
      }
    }
    lookupPartial(name, referrer) {
      if (this.inner.lookupPartial) {
        var partial = this.inner.lookupPartial(name, referrer);
        if (partial === undefined) {
          throw new Error(`Unexpected partial ${name} (from ${referrer}) (lookupPartial returned undefined)`);
        }
        return partial;
      } else {
        throw new Error('lookupPartial not implemented on RuntimeResolver.');
      }
    }
    resolve(handle) {
      if (this.inner.resolve) {
        var resolved = this.inner.resolve(handle);
        if (resolved === undefined) {
          throw new Error(`Unexpected handle ${handle} (resolve returned undefined)`);
        }
        return resolved;
      } else {
        throw new Error('resolve not implemented on RuntimeResolver.');
      }
    }
    compilable(locator) {
      if (this.inner.compilable) {
        var resolved = this.inner.compilable(locator);
        if (resolved === undefined) {
          throw new Error(`Unable to compile ${name} (compilable returned undefined)`);
        }
        return resolved;
      } else {
        throw new Error('compilable not implemented on RuntimeResolver.');
      }
    }
    getInvocation(locator) {
      if (this.inner.getInvocation) {
        var invocation = this.inner.getInvocation(locator);
        if (invocation === undefined) {
          throw new Error(`Unable to get invocation for ${JSON.stringify(locator)} (getInvocation returned undefined)`);
        }
        return invocation;
      } else {
        throw new Error('getInvocation not implemented on RuntimeResolver.');
      }
    }
  }
  function AotRuntime(options, program, resolver = {}, delegate) {
    var env = new EnvironmentImpl(options, delegate);
    return {
      env,
      resolver: new DefaultRuntimeResolver(resolver),
      program: _program.RuntimeProgramImpl.hydrate(program)
    };
  }
  function JitRuntime(options, delegate, context, resolver = {}) {
    return {
      env: new EnvironmentImpl(options, delegate),
      program: new _program.RuntimeProgramImpl(context.program.constants, context.program.heap),
      resolver: new DefaultRuntimeResolver(resolver)
    };
  }
  function inTransaction(env, cb) {
    if (!env[TRANSACTION]) {
      env.begin();
      try {
        cb();
      } finally {
        env.commit();
      }
    } else {
      cb();
    }
  }
  class DynamicVarReference {
    constructor(scope, nameRef) {
      this.scope = scope;
      this.nameRef = nameRef;
    }
    value() {
      return this.getVar().value();
    }
    isConst() {
      return false;
    }
    get(key) {
      return this.getVar().get(key);
    }
    getVar() {
      var name = String(this.nameRef.value());
      return this.scope.get(name);
    }
  }
  function getDynamicVar(args, vm) {
    var scope = vm.dynamicScope();
    var nameRef = args.positional.at(0);
    return new DynamicVarReference(scope, nameRef);
  }
  /*
    The calling convention is:
  
    * 0-N block arguments at the bottom
    * 0-N positional arguments next (left-to-right)
    * 0-N named arguments next
  */

  class VMArgumentsImpl {
    constructor() {
      this.stack = null;
      this.positional = new PositionalArgumentsImpl();
      this.named = new NamedArgumentsImpl();
      this.blocks = new BlockArgumentsImpl();
    }
    empty(stack) {
      var base = stack[REGISTERS][_vm2.$sp] + 1;
      this.named.empty(stack, base);
      this.positional.empty(stack, base);
      this.blocks.empty(stack, base);
      return this;
    }
    setup(stack, names, blockNames, positionalCount, atNames) {
      this.stack = stack;
      /*
             | ... | blocks      | positional  | named |
             | ... | b0    b1    | p0 p1 p2 p3 | n0 n1 |
       index | ... | 4/5/6 7/8/9 | 10 11 12 13 | 14 15 |
                     ^             ^             ^  ^
                   bbase         pbase       nbase  sp
      */

      var named = this.named;
      var namedCount = names.length;
      var namedBase = stack[REGISTERS][_vm2.$sp] - namedCount + 1;
      named.setup(stack, namedBase, namedCount, names, atNames);
      var positional = this.positional;
      var positionalBase = namedBase - positionalCount;
      positional.setup(stack, positionalBase, positionalCount);
      var blocks = this.blocks;
      var blocksCount = blockNames.length;
      var blocksBase = positionalBase - blocksCount * 3;
      blocks.setup(stack, blocksBase, blocksCount, blockNames);
    }
    get base() {
      return this.blocks.base;
    }
    get length() {
      return this.positional.length + this.named.length + this.blocks.length * 3;
    }
    at(pos) {
      return this.positional.at(pos);
    }
    realloc(offset) {
      var {
        stack
      } = this;
      if (offset > 0 && stack !== null) {
        var {
          positional,
          named
        } = this;
        var newBase = positional.base + offset;
        var length = positional.length + named.length;
        for (var i = length - 1; i >= 0; i--) {
          stack.copy(i + positional.base, i + newBase);
        }
        positional.base += offset;
        named.base += offset;
        stack[REGISTERS][_vm2.$sp] += offset;
      }
    }
    capture() {
      var positional = this.positional.length === 0 ? EMPTY_POSITIONAL : this.positional.capture();
      var named = this.named.length === 0 ? EMPTY_NAMED : this.named.capture();
      return {
        named,
        positional
      };
    }
    clear() {
      var {
        stack,
        length
      } = this;
      if (length > 0 && stack !== null) stack.pop(length);
    }
  }
  class PositionalArgumentsImpl {
    constructor() {
      this.base = 0;
      this.length = 0;
      this.stack = null;
      this._references = null;
    }
    empty(stack, base) {
      this.stack = stack;
      this.base = base;
      this.length = 0;
      this._references = _util.EMPTY_ARRAY;
    }
    setup(stack, base, length) {
      this.stack = stack;
      this.base = base;
      this.length = length;
      if (length === 0) {
        this._references = _util.EMPTY_ARRAY;
      } else {
        this._references = null;
      }
    }
    at(position) {
      var {
        base,
        length,
        stack
      } = this;
      if (position < 0 || position >= length) {
        return UNDEFINED_REFERENCE;
      }
      return stack.get(position, base);
    }
    capture() {
      return this.references;
    }
    prepend(other) {
      var additions = other.length;
      if (additions > 0) {
        var {
          base,
          length,
          stack
        } = this;
        this.base = base = base - additions;
        this.length = length + additions;
        for (var i = 0; i < additions; i++) {
          stack.set(other[i], i, base);
        }
        this._references = null;
      }
    }
    get references() {
      var references = this._references;
      if (!references) {
        var {
          stack,
          base,
          length
        } = this;
        references = this._references = stack.slice(base, base + length);
      }
      return references;
    }
  }
  class NamedArgumentsImpl {
    constructor() {
      this.base = 0;
      this.length = 0;
      this._references = null;
      this._names = _util.EMPTY_ARRAY;
      this._atNames = _util.EMPTY_ARRAY;
    }
    empty(stack, base) {
      this.stack = stack;
      this.base = base;
      this.length = 0;
      this._references = _util.EMPTY_ARRAY;
      this._names = _util.EMPTY_ARRAY;
      this._atNames = _util.EMPTY_ARRAY;
    }
    setup(stack, base, length, names, atNames) {
      this.stack = stack;
      this.base = base;
      this.length = length;
      if (length === 0) {
        this._references = _util.EMPTY_ARRAY;
        this._names = _util.EMPTY_ARRAY;
        this._atNames = _util.EMPTY_ARRAY;
      } else {
        this._references = null;
        if (atNames) {
          this._names = null;
          this._atNames = names;
        } else {
          this._names = names;
          this._atNames = null;
        }
      }
    }
    get names() {
      var names = this._names;
      if (!names) {
        names = this._names = this._atNames.map(this.toSyntheticName);
      }
      return names;
    }
    get atNames() {
      var atNames = this._atNames;
      if (!atNames) {
        atNames = this._atNames = this._names.map(this.toAtName);
      }
      return atNames;
    }
    has(name) {
      return this.names.indexOf(name) !== -1;
    }
    get(name, atNames = false) {
      var {
        base,
        stack
      } = this;
      var names = atNames ? this.atNames : this.names;
      var idx = names.indexOf(name);
      if (idx === -1) {
        return UNDEFINED_REFERENCE;
      }
      return stack.get(idx, base);
    }
    capture() {
      var {
        names,
        references
      } = this;
      var map = (0, _util.dict)();
      for (var i = 0; i < names.length; i++) {
        var _name7 = names[i];
        map[_name7] = references[i];
      }
      return map;
    }
    merge(other) {
      var keys = Object.keys(other);
      if (keys.length > 0) {
        var {
          names,
          length,
          stack
        } = this;
        var newNames = names.slice();
        for (var i = 0; i < keys.length; i++) {
          var _name8 = keys[i];
          var idx = newNames.indexOf(_name8);
          if (idx === -1) {
            length = newNames.push(_name8);
            stack.pushJs(other[_name8]);
          }
        }
        this.length = length;
        this._references = null;
        this._names = newNames;
        this._atNames = null;
      }
    }
    get references() {
      var references = this._references;
      if (!references) {
        var {
          base,
          length,
          stack
        } = this;
        references = this._references = stack.slice(base, base + length);
      }
      return references;
    }
    toSyntheticName(name) {
      return name.slice(1);
    }
    toAtName(name) {
      return `@${name}`;
    }
  }
  function toSymbolName(name) {
    return `&${name}`;
  }
  class BlockArgumentsImpl {
    constructor() {
      this.internalValues = null;
      this._symbolNames = null;
      this.internalTag = null;
      this.names = _util.EMPTY_ARRAY;
      this.length = 0;
      this.base = 0;
    }
    empty(stack, base) {
      this.stack = stack;
      this.names = _util.EMPTY_ARRAY;
      this.base = base;
      this.length = 0;
      this._symbolNames = null;
      this.internalTag = _validator.CONSTANT_TAG;
      this.internalValues = _util.EMPTY_ARRAY;
    }
    setup(stack, base, length, names) {
      this.stack = stack;
      this.names = names;
      this.base = base;
      this.length = length;
      this._symbolNames = null;
      if (length === 0) {
        this.internalTag = _validator.CONSTANT_TAG;
        this.internalValues = _util.EMPTY_ARRAY;
      } else {
        this.internalTag = null;
        this.internalValues = null;
      }
    }
    get values() {
      var values = this.internalValues;
      if (!values) {
        var {
          base,
          length,
          stack
        } = this;
        values = this.internalValues = stack.slice(base, base + length * 3);
      }
      return values;
    }
    has(name) {
      return this.names.indexOf(name) !== -1;
    }
    get(name) {
      var idx = this.names.indexOf(name);
      if (idx === -1) {
        return null;
      }
      var {
        base,
        stack
      } = this;
      var table = stack.get(idx * 3, base);
      var scope = stack.get(idx * 3 + 1, base);
      var handle = stack.get(idx * 3 + 2, base);
      return handle === null ? null : [handle, scope, table];
    }
    capture() {
      return new CapturedBlockArgumentsImpl(this.names, this.values);
    }
    get symbolNames() {
      var symbolNames = this._symbolNames;
      if (symbolNames === null) {
        symbolNames = this._symbolNames = this.names.map(toSymbolName);
      }
      return symbolNames;
    }
  }
  class CapturedBlockArgumentsImpl {
    constructor(names, values) {
      this.names = names;
      this.values = values;
      this.length = names.length;
    }
    has(name) {
      return this.names.indexOf(name) !== -1;
    }
    get(name) {
      var idx = this.names.indexOf(name);
      if (idx === -1) return null;
      return [this.values[idx * 3 + 2], this.values[idx * 3 + 1], this.values[idx * 3]];
    }
  }
  function createCapturedArgs(named, positional) {
    return {
      named,
      positional
    };
  }
  function reifyNamed(named) {
    var reified = (0, _util.dict)();
    for (var key in named) {
      reified[key] = named[key].value();
    }
    return reified;
  }
  function reifyPositional(positional) {
    return positional.map(ref => ref.value());
  }
  function reifyArgs(args) {
    return {
      named: reifyNamed(args.named),
      positional: reifyPositional(args.positional)
    };
  }
  class ReifyNamedReference extends _reference.CachedReference {
    constructor(named) {
      super();
      this.named = named;
    }
    compute() {
      return reifyNamed(this.named);
    }
    get(key) {
      var ref = this.named[key];
      return ref === undefined ? UNDEFINED_REFERENCE : ref;
    }
  }
  _exports.ReifyNamedReference = ReifyNamedReference;
  class ReifyPositionalReference extends _reference.CachedReference {
    constructor(positional) {
      super();
      this.positional = positional;
    }
    compute() {
      return reifyPositional(this.positional);
    }
    get(key) {
      var ref;
      if (typeof key === 'number') {
        ref = this.positional[key];
      } else if (key === 'length') {
        ref = new _reference.ConstReference(this.positional.length);
      }
      return ref === undefined ? UNDEFINED_REFERENCE : ref;
    }
  }
  _exports.ReifyPositionalReference = ReifyPositionalReference;
  var EMPTY_NAMED = _exports.EMPTY_NAMED = Object.freeze(Object.create(null));
  var EMPTY_POSITIONAL = _exports.EMPTY_POSITIONAL = _util.EMPTY_ARRAY;
  var EMPTY_ARGS = _exports.EMPTY_ARGS = createCapturedArgs(EMPTY_NAMED, EMPTY_POSITIONAL);
  function initializeRegistersWithSP(sp) {
    return [0, -1, sp, 0];
  }
  class LowLevelVM {
    constructor(stack, heap, program, externs, registers) {
      this.stack = stack;
      this.heap = heap;
      this.program = program;
      this.externs = externs;
      this.registers = registers;
      this.currentOpSize = 0;
    }
    fetchRegister(register) {
      return this.registers[register];
    }
    loadRegister(register, value) {
      this.registers[register] = value;
    }
    setPc(pc) {
      this.registers[_vm2.$pc] = pc;
    } // Start a new frame and save $ra and $fp on the stack

    pushFrame() {
      this.stack.pushSmallInt(this.registers[_vm2.$ra]);
      this.stack.pushSmallInt(this.registers[_vm2.$fp]);
      this.registers[_vm2.$fp] = this.registers[_vm2.$sp] - 1;
    } // Restore $ra, $sp and $fp

    popFrame() {
      this.registers[_vm2.$sp] = this.registers[_vm2.$fp] - 1;
      this.registers[_vm2.$ra] = this.stack.get(0);
      this.registers[_vm2.$fp] = this.stack.get(1);
    }
    pushSmallFrame() {
      this.stack.pushSmallInt(this.registers[_vm2.$ra]);
    }
    popSmallFrame() {
      this.registers[_vm2.$ra] = this.stack.popSmallInt();
    } // Jump to an address in `program`

    goto(offset) {
      this.setPc(this.target(offset));
    }
    target(offset) {
      return this.registers[_vm2.$pc] + offset - this.currentOpSize;
    } // Save $pc into $ra, then jump to a new address in `program` (jal in MIPS)

    call(handle) {
      this.registers[_vm2.$ra] = this.registers[_vm2.$pc];
      this.setPc(this.heap.getaddr(handle));
    } // Put a specific `program` address in $ra

    returnTo(offset) {
      this.registers[_vm2.$ra] = this.target(offset);
    } // Return to the `program` address stored in $ra

    return() {
      this.setPc(this.registers[_vm2.$ra]);
    }
    nextStatement() {
      var {
        registers,
        program
      } = this;
      var pc = registers[_vm2.$pc];
      if (pc === -1) {
        return null;
      } // We have to save off the current operations size so that
      // when we do a jump we can calculate the correct offset
      // to where we are going. We can't simply ask for the size
      // in a jump because we have have already incremented the
      // program counter to the next instruction prior to executing.

      var opcode = program.opcode(pc);
      var operationSize = this.currentOpSize = opcode.size;
      this.registers[_vm2.$pc] += operationSize;
      return opcode;
    }
    evaluateOuter(opcode, vm) {
      {
        this.evaluateInner(opcode, vm);
      }
    }
    evaluateInner(opcode, vm) {
      if (opcode.isMachine) {
        this.evaluateMachine(opcode);
      } else {
        this.evaluateSyscall(opcode, vm);
      }
    }
    evaluateMachine(opcode) {
      switch (opcode.type) {
        case 0
        /* PushFrame */:
          return this.pushFrame();
        case 1
        /* PopFrame */:
          return this.popFrame();
        case 3
        /* InvokeStatic */:
          return this.call(opcode.op1);
        case 2
        /* InvokeVirtual */:
          return this.call(this.stack.popSmallInt());
        case 4
        /* Jump */:
          return this.goto(opcode.op1);
        case 5
        /* Return */:
          return this.return();
        case 6
        /* ReturnTo */:
          return this.returnTo(opcode.op1);
      }
    }
    evaluateSyscall(opcode, vm) {
      APPEND_OPCODES.evaluate(vm, opcode, opcode.type);
    }
  }
  class UpdatingVM {
    constructor(env, {
      alwaysRevalidate = false
    }) {
      this.frameStack = new _util.Stack();
      this.env = env;
      this.dom = env.getDOM();
      this.alwaysRevalidate = alwaysRevalidate;
    }
    execute(opcodes, handler) {
      var {
        frameStack
      } = this;
      this.try(opcodes, handler);
      try {
        while (true) {
          if (frameStack.isEmpty()) break;
          var opcode = this.frame.nextStatement();
          if (opcode === undefined) {
            frameStack.pop();
            continue;
          }
          opcode.evaluate(this);
        }
      } catch (e) {
        (0, _validator.resetTracking)();
        throw e;
      }
    }
    get frame() {
      return this.frameStack.current;
    }
    goto(index) {
      this.frame.goto(index);
    }
    try(ops, handler) {
      this.frameStack.push(new UpdatingVMFrame(ops, handler));
    }
    throw() {
      this.frame.handleException();
      this.frameStack.pop();
    }
  }
  _exports.UpdatingVM = UpdatingVM;
  class ResumableVMStateImpl {
    constructor(state, resumeCallback) {
      this.state = state;
      this.resumeCallback = resumeCallback;
    }
    resume(runtime, builder) {
      return this.resumeCallback(runtime, this.state, builder);
    }
  }
  class BlockOpcode extends UpdatingOpcode {
    constructor(state, runtime, bounds, children) {
      super();
      this.state = state;
      this.runtime = runtime;
      this.type = 'block';
      this.children = children;
      this.bounds = bounds;
    }
    parentElement() {
      return this.bounds.parentElement();
    }
    firstNode() {
      return this.bounds.firstNode();
    }
    lastNode() {
      return this.bounds.lastNode();
    }
    evaluate(vm) {
      vm.try(this.children, null);
    }
  }
  class TryOpcode extends BlockOpcode {
    constructor() {
      super(...arguments);
      this.type = 'try';
    }
    evaluate(vm) {
      vm.try(this.children, this);
    }
    handleException() {
      var {
        state,
        bounds,
        runtime
      } = this;
      destroyChildren(this);
      var elementStack = NewElementBuilder.resume(runtime.env, bounds);
      var vm = state.resume(runtime, elementStack);
      var updating = [];
      var children = this.children = [];
      var result = vm.execute(vm => {
        vm.pushUpdating(updating);
        vm.updateWith(this);
        vm.pushUpdating(children);
      });
      associateDestroyableChild(this, result.drop);
    }
  }
  class ListItemOpcode extends TryOpcode {
    constructor(state, runtime, bounds, key, memo, value) {
      super(state, runtime, bounds, []);
      this.key = key;
      this.memo = memo;
      this.value = value;
      this.retained = false;
      this.index = -1;
    }
    updateReferences(item) {
      this.retained = true;
      this.value.update(item.value);
      this.memo.update(item.memo);
    }
    shouldRemove() {
      return !this.retained;
    }
    reset() {
      this.retained = false;
    }
  }
  class ListBlockOpcode extends BlockOpcode {
    constructor(state, runtime, bounds, children, iterableRef) {
      super(state, runtime, bounds, children);
      this.iterableRef = iterableRef;
      this.type = 'list-block';
      this.opcodeMap = new Map();
      this.marker = null;
    }
    initializeChild(opcode) {
      opcode.index = this.children.length - 1;
      this.opcodeMap.set(opcode.key, opcode);
    }
    evaluate(vm) {
      if (this.iterableRef.isDone() === false) {
        var {
          bounds
        } = this;
        var {
          dom
        } = vm;
        var marker = this.marker = dom.createComment('');
        dom.insertAfter(bounds.parentElement(), marker, bounds.lastNode());
        this.sync();
        this.parentElement().removeChild(marker);
        this.marker = null;
      } // Run now-updated updating opcodes

      super.evaluate(vm);
    }
    sync() {
      var {
        iterableRef,
        opcodeMap: itemMap,
        children
      } = this;
      var currentOpcodeIndex = 0;
      var seenIndex = 0;
      this.children = this.bounds.boundList = [];
      while (true) {
        var item = iterableRef.next();
        if (item === null) break;
        var opcode = children[currentOpcodeIndex];
        var {
          key
        } = item; // Items that have already been found and moved will already be retained,
        // we can continue until we find the next unretained item

        while (opcode !== undefined && opcode.retained === true) {
          opcode = children[++currentOpcodeIndex];
        }
        if (opcode !== undefined && opcode.key === key) {
          this.retainItem(opcode, item);
          currentOpcodeIndex++;
        } else if (itemMap.has(key)) {
          var itemOpcode = itemMap.get(key); // The item opcode was seen already, so we should move it.

          if (itemOpcode.index < seenIndex) {
            this.moveItem(itemOpcode, item, opcode);
          } else {
            // Update the seen index, we are going to be moving this item around
            // so any other items that come before it will likely need to move as
            // well.
            seenIndex = itemOpcode.index;
            var seenUnretained = false; // iterate through all of the opcodes between the current position and
            // the position of the item's opcode, and determine if they are all
            // retained.

            for (var i = currentOpcodeIndex + 1; i < seenIndex; i++) {
              if (children[i].retained === false) {
                seenUnretained = true;
                break;
              }
            } // If we have seen only retained opcodes between this and the matching
            // opcode, it means that all the opcodes in between have been moved
            // already, and we can safely retain this item's opcode.

            if (seenUnretained === false) {
              this.retainItem(itemOpcode, item);
              currentOpcodeIndex = seenIndex + 1;
            } else {
              this.moveItem(itemOpcode, item, opcode);
              currentOpcodeIndex++;
            }
          }
        } else {
          this.insertItem(item, opcode);
        }
      }
      for (var _i7 = 0; _i7 < children.length; _i7++) {
        var _opcode = children[_i7];
        if (_opcode.retained === false) {
          this.deleteItem(_opcode);
        } else {
          _opcode.reset();
        }
      }
    }
    retainItem(opcode, item) {
      var {
        children
      } = this;
      opcode.memo.update(item.memo);
      opcode.value.update(item.value);
      opcode.retained = true;
      opcode.index = children.length;
      children.push(opcode);
    }
    insertItem(item, before) {
      var {
        opcodeMap,
        bounds,
        state,
        runtime,
        iterableRef,
        children
      } = this;
      var {
        key
      } = item;
      var nextSibling = before === undefined ? this.marker : before.firstNode();
      var elementStack = NewElementBuilder.forInitialRender(runtime.env, {
        element: bounds.parentElement(),
        nextSibling
      });
      var vm = state.resume(runtime, elementStack);
      vm.execute(vm => {
        vm.pushUpdating();
        var opcode = vm.enterItem(iterableRef, item);
        opcode.index = children.length;
        children.push(opcode);
        opcodeMap.set(key, opcode);
        associateDestroyableChild(this, opcode);
      });
    }
    moveItem(opcode, item, before) {
      var {
        children
      } = this;
      opcode.memo.update(item.memo);
      opcode.value.update(item.value);
      opcode.retained = true;
      var currentSibling, nextSibling;
      if (before === undefined) {
        move(opcode, this.marker);
      } else {
        currentSibling = opcode.lastNode().nextSibling;
        nextSibling = before.firstNode(); // Items are moved throughout the algorithm, so there are cases where the
        // the items already happen to be siblings (e.g. an item in between was
        // moved before this move happened). Check to see if they are siblings
        // first before doing the move.

        if (currentSibling !== nextSibling) {
          move(opcode, nextSibling);
        }
      }
      opcode.index = children.length;
      children.push(opcode);
    }
    deleteItem(opcode) {
      destroy(opcode);
      clear(opcode);
      this.opcodeMap.delete(opcode.key);
    }
  }
  class UpdatingVMFrame {
    constructor(ops, exceptionHandler) {
      this.ops = ops;
      this.exceptionHandler = exceptionHandler;
      this.current = 0;
    }
    goto(index) {
      this.current = index;
    }
    nextStatement() {
      return this.ops[this.current++];
    }
    handleException() {
      if (this.exceptionHandler) {
        this.exceptionHandler.handleException();
      }
    }
  }
  class RenderResultImpl {
    constructor(env, updating, bounds, drop) {
      this.env = env;
      this.updating = updating;
      this.bounds = bounds;
      this.drop = drop;
      associateDestroyableChild(this, drop);
      registerDestructor(this, () => clear(this.bounds));
    }
    rerender({
      alwaysRevalidate = false
    } = {
      alwaysRevalidate: false
    }) {
      var {
        env,
        updating
      } = this;
      var vm = new UpdatingVM(env, {
        alwaysRevalidate
      });
      vm.execute(updating, this);
    }
    parentElement() {
      return this.bounds.parentElement();
    }
    firstNode() {
      return this.bounds.firstNode();
    }
    lastNode() {
      return this.bounds.lastNode();
    }
    handleException() {
      throw 'this should never happen';
    }
  }
  class InnerStack {
    constructor(inner = new _lowLevel.Stack(), js) {
      this.inner = inner;
      this.js = (0, _util.constants)();
      if (js !== undefined) {
        this.js = this.js.concat(js);
      }
    }
    slice(start, end) {
      var out = [];
      if (start === -1) {
        return out;
      }
      for (var i = start; i < end; i++) {
        out.push(this.get(i));
      }
      return out;
    }
    copy(from, to) {
      this.inner.copy(from, to);
    }
    writeJs(pos, value) {
      var idx = this.js.length;
      this.js.push(value);
      this.inner.writeRaw(pos, (0, _util.encodeHandle)(idx));
    }
    writeSmallInt(pos, value) {
      this.inner.writeRaw(pos, (0, _util.encodeImmediate)(value));
    }
    writeTrue(pos) {
      this.inner.writeRaw(pos, 1
      /* ENCODED_TRUE_HANDLE */);
    }
    writeFalse(pos) {
      this.inner.writeRaw(pos, 0
      /* ENCODED_FALSE_HANDLE */);
    }
    writeNull(pos) {
      this.inner.writeRaw(pos, 2
      /* ENCODED_NULL_HANDLE */);
    }
    writeUndefined(pos) {
      this.inner.writeRaw(pos, 3
      /* ENCODED_UNDEFINED_HANDLE */);
    }
    writeRaw(pos, value) {
      this.inner.writeRaw(pos, value);
    }
    getJs(pos) {
      var value = this.inner.getRaw(pos);
      return this.js[(0, _util.decodeHandle)(value)];
    }
    getSmallInt(pos) {
      var value = this.inner.getRaw(pos);
      return (0, _util.decodeImmediate)(value);
    }
    get(pos) {
      var value = this.inner.getRaw(pos) | 0;
      if ((0, _util.isHandle)(value)) {
        return this.js[(0, _util.decodeHandle)(value)];
      } else {
        return (0, _util.decodeImmediate)(value);
      }
    }
    reset() {
      this.inner.reset();
      this.js.length = 0;
    }
    get length() {
      return this.inner.len();
    }
  }
  class EvaluationStackImpl {
    // fp -> sp
    constructor(stack, registers) {
      this.stack = stack;
      this[REGISTERS] = registers;
    }
    static restore(snapshot) {
      var stack = new InnerStack();
      for (var i = 0; i < snapshot.length; i++) {
        var value = snapshot[i];
        if (typeof value === 'number' && (0, _util.isSmallInt)(value)) {
          stack.writeRaw(i, (0, _util.encodeImmediate)(value));
        } else if (value === true) {
          stack.writeTrue(i);
        } else if (value === false) {
          stack.writeFalse(i);
        } else if (value === null) {
          stack.writeNull(i);
        } else if (value === undefined) {
          stack.writeUndefined(i);
        } else {
          stack.writeJs(i, value);
        }
      }
      return new this(stack, initializeRegistersWithSP(snapshot.length - 1));
    }
    pushJs(value) {
      this.stack.writeJs(++this[REGISTERS][_vm2.$sp], value);
    }
    pushSmallInt(value) {
      this.stack.writeSmallInt(++this[REGISTERS][_vm2.$sp], value);
    }
    pushTrue() {
      this.stack.writeTrue(++this[REGISTERS][_vm2.$sp]);
    }
    pushFalse() {
      this.stack.writeFalse(++this[REGISTERS][_vm2.$sp]);
    }
    pushNull() {
      this.stack.writeNull(++this[REGISTERS][_vm2.$sp]);
    }
    pushUndefined() {
      this.stack.writeUndefined(++this[REGISTERS][_vm2.$sp]);
    }
    pushRaw(value) {
      this.stack.writeRaw(++this[REGISTERS][_vm2.$sp], value);
    }
    dup(position = this[REGISTERS][_vm2.$sp]) {
      this.stack.copy(position, ++this[REGISTERS][_vm2.$sp]);
    }
    copy(from, to) {
      this.stack.copy(from, to);
    }
    popJs(n = 1) {
      var top = this.stack.getJs(this[REGISTERS][_vm2.$sp]);
      this[REGISTERS][_vm2.$sp] -= n;
      return top;
    }
    popSmallInt(n = 1) {
      var top = this.stack.getSmallInt(this[REGISTERS][_vm2.$sp]);
      this[REGISTERS][_vm2.$sp] -= n;
      return top;
    }
    pop(n = 1) {
      var top = this.stack.get(this[REGISTERS][_vm2.$sp]);
      this[REGISTERS][_vm2.$sp] -= n;
      return top;
    }
    peekJs(offset = 0) {
      return this.stack.getJs(this[REGISTERS][_vm2.$sp] - offset);
    }
    peekSmallInt(offset = 0) {
      return this.stack.getSmallInt(this[REGISTERS][_vm2.$sp] - offset);
    }
    peek(offset = 0) {
      return this.stack.get(this[REGISTERS][_vm2.$sp] - offset);
    }
    get(offset, base = this[REGISTERS][_vm2.$fp]) {
      return this.stack.get(base + offset);
    }
    set(value, offset, base = this[REGISTERS][_vm2.$fp]) {
      this.stack.writeJs(base + offset, value);
    }
    slice(start, end) {
      return this.stack.slice(start, end);
    }
    capture(items) {
      var end = this[REGISTERS][_vm2.$sp] + 1;
      var start = end - items;
      return this.stack.slice(start, end);
    }
    reset() {
      this.stack.reset();
    }
    toArray() {
      console.log(this[REGISTERS]);
      return this.stack.slice(this[REGISTERS][_vm2.$fp], this[REGISTERS][_vm2.$sp] + 1);
    }
  }
  var _a$3, _b;
  class Stacks {
    constructor() {
      this.scope = new _util.Stack();
      this.dynamicScope = new _util.Stack();
      this.updating = new _util.Stack();
      this.cache = new _util.Stack();
      this.list = new _util.Stack();
    }
  }
  class VM {
    /**
     * End of migrated.
     */
    constructor(runtime, {
      pc,
      scope,
      dynamicScope,
      stack
    }, elementStack) {
      this.runtime = runtime;
      this.elementStack = elementStack;
      this[_a$3] = new Stacks();
      this[_b] = new _util.Stack();
      this.s0 = null;
      this.s1 = null;
      this.t0 = null;
      this.t1 = null;
      this.v0 = null;
      if (false /* DEBUG */) {
        (0, _globalContext.assertGlobalContextWasSet)();
      }
      var evalStack = EvaluationStackImpl.restore(stack);
      evalStack[REGISTERS][_vm2.$pc] = pc;
      evalStack[REGISTERS][_vm2.$sp] = stack.length - 1;
      evalStack[REGISTERS][_vm2.$fp] = -1;
      this[HEAP] = this.program.heap;
      this[CONSTANTS] = this.program.constants;
      this.elementStack = elementStack;
      this[STACKS].scope.push(scope);
      this[STACKS].dynamicScope.push(dynamicScope);
      this[ARGS] = new VMArgumentsImpl();
      this[INNER_VM] = new LowLevelVM(evalStack, this[HEAP], runtime.program, {
        debugBefore: opcode => {
          return APPEND_OPCODES.debugBefore(this, opcode);
        },
        debugAfter: state => {
          APPEND_OPCODES.debugAfter(this, state);
        }
      }, evalStack[REGISTERS]);
      this.destructor = {};
      this[DESTROYABLE_STACK].push(this.destructor);
    }
    get stack() {
      return this[INNER_VM].stack;
    }
    /* Registers */

    get pc() {
      return this[INNER_VM].fetchRegister(_vm2.$pc);
    } // Fetch a value from a register onto the stack

    fetch(register) {
      var value = this.fetchValue(register);
      this.stack.pushJs(value);
    } // Load a value from the stack into a register

    load(register) {
      var value = this.stack.pop();
      this.loadValue(register, value);
    }
    fetchValue(register) {
      if ((0, _vm2.isLowLevelRegister)(register)) {
        return this[INNER_VM].fetchRegister(register);
      }
      switch (register) {
        case _vm2.$s0:
          return this.s0;
        case _vm2.$s1:
          return this.s1;
        case _vm2.$t0:
          return this.t0;
        case _vm2.$t1:
          return this.t1;
        case _vm2.$v0:
          return this.v0;
      }
    } // Load a value into a register

    loadValue(register, value) {
      if ((0, _vm2.isLowLevelRegister)(register)) {
        this[INNER_VM].loadRegister(register, value);
      }
      switch (register) {
        case _vm2.$s0:
          this.s0 = value;
          break;
        case _vm2.$s1:
          this.s1 = value;
          break;
        case _vm2.$t0:
          this.t0 = value;
          break;
        case _vm2.$t1:
          this.t1 = value;
          break;
        case _vm2.$v0:
          this.v0 = value;
          break;
      }
    }
    /**
     * Migrated to Inner
     */
    // Start a new frame and save $ra and $fp on the stack

    pushFrame() {
      this[INNER_VM].pushFrame();
    } // Restore $ra, $sp and $fp

    popFrame() {
      this[INNER_VM].popFrame();
    } // Jump to an address in `program`

    goto(offset) {
      this[INNER_VM].goto(offset);
    } // Save $pc into $ra, then jump to a new address in `program` (jal in MIPS)

    call(handle) {
      this[INNER_VM].call(handle);
    } // Put a specific `program` address in $ra

    returnTo(offset) {
      this[INNER_VM].returnTo(offset);
    } // Return to the `program` address stored in $ra

    return() {
      this[INNER_VM].return();
    }
    get program() {
      return this.runtime.program;
    }
    get env() {
      return this.runtime.env;
    }
    captureState(args, pc = this[INNER_VM].fetchRegister(_vm2.$pc)) {
      return {
        pc,
        dynamicScope: this.dynamicScope(),
        scope: this.scope(),
        stack: this.stack.capture(args)
      };
    }
    beginCacheGroup(name) {
      var opcodes = this.updating();
      var guard = new JumpIfNotModifiedOpcode();
      opcodes.push(guard);
      opcodes.push(new BeginTrackFrameOpcode(name));
      this[STACKS].cache.push(guard);
      (0, _validator.beginTrackFrame)(name);
    }
    commitCacheGroup() {
      var opcodes = this.updating();
      var guard = this[STACKS].cache.pop();
      var tag = (0, _validator.endTrackFrame)();
      opcodes.push(new EndTrackFrameOpcode(guard));
      guard.finalize(tag, opcodes.length);
    }
    enter(args) {
      var updating = [];
      var state = this.capture(args);
      var block = this.elements().pushUpdatableBlock();
      var tryOpcode = new TryOpcode(state, this.runtime, block, updating);
      this.didEnter(tryOpcode);
    }
    enterItem(iterableRef, {
      key,
      value,
      memo
    }) {
      var {
        stack
      } = this;
      var valueRef = iterableRef.childRefFor(key, value);
      var memoRef = iterableRef.childRefFor(key, memo);
      stack.pushJs(valueRef);
      stack.pushJs(memoRef);
      var state = this.capture(2);
      var block = this.elements().pushUpdatableBlock();
      var opcode = new ListItemOpcode(state, this.runtime, block, key, memoRef, valueRef);
      this.didEnter(opcode);
      return opcode;
    }
    registerItem(opcode) {
      this.listBlock().initializeChild(opcode);
    }
    enterList(offset) {
      var updating = [];
      var addr = this[INNER_VM].target(offset);
      var state = this.capture(0, addr);
      var list = this.elements().pushBlockList(updating);
      var iterableRef = this.stack.peekJs();
      var opcode = new ListBlockOpcode(state, this.runtime, list, updating, iterableRef);
      this[STACKS].list.push(opcode);
      this.didEnter(opcode);
    }
    didEnter(opcode) {
      this.associateDestroyable(opcode);
      this[DESTROYABLE_STACK].push(opcode);
      this.updateWith(opcode);
      this.pushUpdating(opcode.children);
    }
    exit() {
      this[DESTROYABLE_STACK].pop();
      this.elements().popBlock();
      this.popUpdating();
    }
    exitList() {
      this.exit();
      this[STACKS].list.pop();
    }
    pushUpdating(list = []) {
      this[STACKS].updating.push(list);
    }
    popUpdating() {
      return this[STACKS].updating.pop();
    }
    updateWith(opcode) {
      this.updating().push(opcode);
    }
    listBlock() {
      return this[STACKS].list.current;
    }
    associateDestroyable(child) {
      var parent = this[DESTROYABLE_STACK].current;
      associateDestroyableChild(parent, child);
    }
    tryUpdating() {
      return this[STACKS].updating.current;
    }
    updating() {
      return this[STACKS].updating.current;
    }
    elements() {
      return this.elementStack;
    }
    scope() {
      return this[STACKS].scope.current;
    }
    dynamicScope() {
      return this[STACKS].dynamicScope.current;
    }
    pushChildScope() {
      this[STACKS].scope.push(this.scope().child());
    }
    pushDynamicScope() {
      var child = this.dynamicScope().child();
      this[STACKS].dynamicScope.push(child);
      return child;
    }
    pushRootScope(size) {
      var scope = PartialScopeImpl.sized(size);
      this[STACKS].scope.push(scope);
      return scope;
    }
    pushScope(scope) {
      this[STACKS].scope.push(scope);
    }
    popScope() {
      this[STACKS].scope.pop();
    }
    popDynamicScope() {
      this[STACKS].dynamicScope.pop();
    } /// SCOPE HELPERS

    getSelf() {
      return this.scope().getSelf();
    }
    referenceForSymbol(symbol$$1) {
      return this.scope().getSymbol(symbol$$1);
    } /// EXECUTION

    execute(initialize) {
      if (initialize) initialize(this);
      var result;
      try {
        while (true) {
          result = this.next();
          if (result.done) break;
        }
      } catch (e) {
        // If any existing blocks are open, due to an error or something like
        // that, we need to close them all and clean things up properly.
        var elements = this.elements();
        while (elements.hasBlocks) {
          elements.popBlock();
        }
        (0, _validator.resetTracking)();
        throw e;
      }
      return result.value;
    }
    next() {
      var {
        env,
        elementStack
      } = this;
      var opcode = this[INNER_VM].nextStatement();
      var result;
      if (opcode !== null) {
        this[INNER_VM].evaluateOuter(opcode, this);
        result = {
          done: false,
          value: null
        };
      } else {
        // Unload the stack
        this.stack.reset();
        result = {
          done: true,
          value: new RenderResultImpl(env, this.popUpdating(), elementStack.popBlock(), this.destructor)
        };
      }
      return result;
    }
    bindDynamicScope(names) {
      var scope = this.dynamicScope();
      for (var i = names.length - 1; i >= 0; i--) {
        var _name9 = names[i];
        scope.set(_name9, this.stack.popJs());
      }
    }
  }
  _exports.LowLevelVM = VM;
  _a$3 = STACKS, _b = DESTROYABLE_STACK;
  function vmState(pc, scope = PartialScopeImpl.root(UNDEFINED_REFERENCE, 0), dynamicScope) {
    return {
      pc,
      scope,
      dynamicScope,
      stack: []
    };
  }
  class AotVM extends VM {
    static empty(runtime, {
      handle,
      treeBuilder,
      dynamicScope
    }) {
      var vm = initAOT(runtime, vmState(runtime.program.heap.getaddr(handle), PartialScopeImpl.root(UNDEFINED_REFERENCE, 0), dynamicScope), treeBuilder);
      vm.pushUpdating();
      return vm;
    }
    static initial(runtime, {
      handle,
      self,
      treeBuilder,
      dynamicScope
    }) {
      var scopeSize = runtime.program.heap.scopesizeof(handle);
      var scope = PartialScopeImpl.root(self, scopeSize);
      var pc = runtime.program.heap.getaddr(handle);
      var state = vmState(pc, scope, dynamicScope);
      var vm = initAOT(runtime, state, treeBuilder);
      vm.pushUpdating();
      return vm;
    }
    capture(args, pc = this[INNER_VM].fetchRegister(_vm2.$pc)) {
      return new ResumableVMStateImpl(this.captureState(args, pc), initAOT);
    }
  }
  function initAOT(runtime, state, builder) {
    return new AotVM(runtime, state, builder);
  }
  function initJIT(context) {
    return (runtime, state, builder) => new JitVM(runtime, state, builder, context);
  }
  class JitVM extends VM {
    constructor(runtime, state, elementStack, context) {
      super(runtime, state, elementStack);
      this.context = context;
      this.resume = initJIT(this.context);
    }
    static initial(runtime, context, {
      handle,
      self,
      dynamicScope,
      treeBuilder
    }) {
      var scopeSize = runtime.program.heap.scopesizeof(handle);
      var scope = PartialScopeImpl.root(self, scopeSize);
      var state = vmState(runtime.program.heap.getaddr(handle), scope, dynamicScope);
      var vm = initJIT(context)(runtime, state, treeBuilder);
      vm.pushUpdating();
      return vm;
    }
    static empty(runtime, {
      handle,
      treeBuilder,
      dynamicScope
    }, context) {
      var vm = initJIT(context)(runtime, vmState(runtime.program.heap.getaddr(handle), PartialScopeImpl.root(UNDEFINED_REFERENCE, 0), dynamicScope), treeBuilder);
      vm.pushUpdating();
      return vm;
    }
    capture(args, pc = this[INNER_VM].fetchRegister(_vm2.$pc)) {
      return new ResumableVMStateImpl(this.captureState(args, pc), this.resume);
    }
    compile(block) {
      var handle = (0, _util.unwrapHandle)(block.compile(this.context));
      return handle;
    }
  }
  class TemplateIteratorImpl {
    constructor(vm) {
      this.vm = vm;
    }
    next() {
      return this.vm.next();
    }
    sync() {
      return this.vm.execute();
    }
  }
  function renderSync(env, iterator) {
    var result;
    inTransaction(env, () => result = iterator.sync());
    return result;
  }
  function renderAotMain(runtime, self, treeBuilder, handle, dynamicScope = new DynamicScopeImpl()) {
    var vm = AotVM.initial(runtime, {
      self,
      dynamicScope,
      treeBuilder,
      handle
    });
    return new TemplateIteratorImpl(vm);
  }
  function renderAot(runtime, handle, cursor, self = UNDEFINED_REFERENCE) {
    var treeBuilder = NewElementBuilder.forInitialRender(runtime.env, cursor);
    var dynamicScope = new DynamicScopeImpl();
    var vm = AotVM.initial(runtime, {
      self,
      dynamicScope,
      treeBuilder,
      handle
    });
    return new TemplateIteratorImpl(vm);
  }
  function renderJitMain(runtime, context, self, treeBuilder, handle, dynamicScope = new DynamicScopeImpl()) {
    var vm = JitVM.initial(runtime, context, {
      self,
      dynamicScope,
      treeBuilder,
      handle
    });
    return new TemplateIteratorImpl(vm);
  }
  function renderInvocation(vm, invocation, definition, args) {
    // Get a list of tuples of argument names and references, like
    // [['title', reference], ['name', reference]]
    var argList = Object.keys(args).map(key => [key, args[key]]);
    var blockNames = ['main', 'else', 'attrs']; // Prefix argument names with `@` symbol

    var argNames = argList.map(([name]) => `@${name}`);
    vm.pushFrame(); // Push blocks on to the stack, three stack values per block

    for (var i = 0; i < 3 * blockNames.length; i++) {
      vm.stack.pushNull();
    }
    vm.stack.pushNull(); // For each argument, push its backing reference on to the stack

    argList.forEach(([, reference]) => {
      vm.stack.pushJs(reference);
    }); // Configure VM based on blocks and args just pushed on to the stack.

    vm[ARGS].setup(vm.stack, argNames, blockNames, 0, true); // Needed for the Op.Main opcode: arguments, component invocation object, and
    // component definition.

    vm.stack.pushJs(vm[ARGS]);
    vm.stack.pushJs(invocation);
    vm.stack.pushJs(definition);
    return new TemplateIteratorImpl(vm);
  }
  function renderAotComponent(runtime, treeBuilder, main, name, args = {}, dynamicScope = new DynamicScopeImpl()) {
    var vm = AotVM.empty(runtime, {
      treeBuilder,
      handle: main,
      dynamicScope
    });
    var definition = resolveComponent(vm.runtime.resolver, name);
    var {
      manager,
      state
    } = definition;
    var capabilities = capabilityFlagsFrom(manager.getCapabilities(state));
    var invocation;
    if (hasStaticLayoutCapability(capabilities, manager)) {
      invocation = manager.getAotStaticLayout(state, vm.runtime.resolver);
    } else {
      throw new Error('Cannot invoke components with dynamic layouts as a root component.');
    }
    return renderInvocation(vm, invocation, definition, args);
  }
  function renderJitComponent(runtime, treeBuilder, context, definition, layout, args = {}, dynamicScope = new DynamicScopeImpl()) {
    var handle = (0, _util.unwrapHandle)(layout.compile(context));
    var invocation = {
      handle,
      symbolTable: layout.symbolTable
    };
    var vm = JitVM.empty(runtime, {
      treeBuilder,
      handle: context.program.stdlib.main,
      dynamicScope
    }, context);
    return renderInvocation(vm, invocation, definition, args);
  }
  var SERIALIZATION_FIRST_NODE_STRING = _exports.SERIALIZATION_FIRST_NODE_STRING = '%+b:0%';
  function isSerializationFirstNode(node) {
    return node.nodeValue === SERIALIZATION_FIRST_NODE_STRING;
  }
  class RehydratingCursor extends CursorImpl {
    constructor(element, nextSibling, startingBlockDepth) {
      super(element, nextSibling);
      this.startingBlockDepth = startingBlockDepth;
      this.candidate = null;
      this.injectedOmittedNode = false;
      this.openBlockDepth = startingBlockDepth - 1;
    }
  }
  class RehydrateBuilder extends NewElementBuilder {
    constructor(env, parentNode, nextSibling) {
      super(env, parentNode, nextSibling);
      this.unmatchedAttributes = null;
      this.blockDepth = 0;
      if (nextSibling) throw new Error('Rehydration with nextSibling not supported');
      var node = this.currentCursor.element.firstChild;
      while (node !== null) {
        if (isComment(node) && isSerializationFirstNode(node)) {
          break;
        }
        node = node.nextSibling;
      }
      this.candidate = node;
    }
    get currentCursor() {
      return this[CURSOR_STACK].current;
    }
    get candidate() {
      if (this.currentCursor) {
        return this.currentCursor.candidate;
      }
      return null;
    }
    set candidate(node) {
      var currentCursor = this.currentCursor;
      currentCursor.candidate = node;
    }
    disableRehydration(nextSibling) {
      var currentCursor = this.currentCursor; // rehydration will be disabled until we either:
      // * hit popElement (and return to using the parent elements cursor)
      // * hit closeBlock and the next sibling is a close block comment
      //   matching the expected openBlockDepth

      currentCursor.candidate = null;
      currentCursor.nextSibling = nextSibling;
    }
    enableRehydration(candidate) {
      var currentCursor = this.currentCursor;
      currentCursor.candidate = candidate;
      currentCursor.nextSibling = null;
    }
    pushElement(element, nextSibling = null) {
      var cursor = new RehydratingCursor(element, nextSibling, this.blockDepth || 0);
      /**
       * <div>   <---------------  currentCursor.element
       *   <!--%+b:1%--> <-------  would have been removed during openBlock
       *   <div> <---------------  currentCursor.candidate -> cursor.element
       *     <!--%+b:2%--> <-----  currentCursor.candidate.firstChild -> cursor.candidate
       *     Foo
       *     <!--%-b:2%-->
       *   </div>
       *   <!--%-b:1%-->  <------  becomes currentCursor.candidate
       */

      if (this.candidate !== null) {
        cursor.candidate = element.firstChild;
        this.candidate = element.nextSibling;
      }
      this[CURSOR_STACK].push(cursor);
    } // clears until the end of the current container
    // either the current open block or higher

    clearMismatch(candidate) {
      var current = candidate;
      var currentCursor = this.currentCursor;
      if (currentCursor !== null) {
        var openBlockDepth = currentCursor.openBlockDepth;
        if (openBlockDepth >= currentCursor.startingBlockDepth) {
          while (current) {
            if (isCloseBlock(current)) {
              var closeBlockDepth = getBlockDepth(current);
              if (openBlockDepth >= closeBlockDepth) {
                break;
              }
            }
            current = this.remove(current);
          }
        } else {
          while (current !== null) {
            current = this.remove(current);
          }
        } // current cursor parentNode should be openCandidate if element
        // or openCandidate.parentNode if comment

        this.disableRehydration(current);
      }
    }
    __openBlock() {
      var {
        currentCursor
      } = this;
      if (currentCursor === null) return;
      var blockDepth = this.blockDepth;
      this.blockDepth++;
      var {
        candidate
      } = currentCursor;
      if (candidate === null) return;
      var {
        tagName
      } = currentCursor.element;
      if (isOpenBlock(candidate) && getBlockDepth(candidate) === blockDepth) {
        this.candidate = this.remove(candidate);
        currentCursor.openBlockDepth = blockDepth;
      } else if (tagName !== 'TITLE' && tagName !== 'SCRIPT' && tagName !== 'STYLE') {
        this.clearMismatch(candidate);
      }
    }
    __closeBlock() {
      var {
        currentCursor
      } = this;
      if (currentCursor === null) return; // openBlock is the last rehydrated open block

      var openBlockDepth = currentCursor.openBlockDepth; // this currently is the expected next open block depth

      this.blockDepth--;
      var {
        candidate
      } = currentCursor;
      var isRehydrating = false;
      if (candidate !== null) {
        isRehydrating = true; //assert(
        //  openBlockDepth === this.blockDepth,
        //  'when rehydrating, openBlockDepth should match this.blockDepth here'
        //);

        if (isCloseBlock(candidate) && getBlockDepth(candidate) === openBlockDepth) {
          var nextSibling = this.remove(candidate);
          this.candidate = nextSibling;
          currentCursor.openBlockDepth--;
        } else {
          // close the block and clear mismatch in parent container
          // we will be either at the end of the element
          // or at the end of our containing block
          this.clearMismatch(candidate);
          isRehydrating = false;
        }
      }
      if (isRehydrating === false) {
        // check if nextSibling matches our expected close block
        // if so, we remove the close block comment and
        // restore rehydration after clearMismatch disabled
        var _nextSibling = currentCursor.nextSibling;
        if (_nextSibling !== null && isCloseBlock(_nextSibling) && getBlockDepth(_nextSibling) === this.blockDepth) {
          // restore rehydration state
          var _candidate2 = this.remove(_nextSibling);
          this.enableRehydration(_candidate2);
          currentCursor.openBlockDepth--;
        }
      }
    }
    __appendNode(node) {
      var {
        candidate
      } = this; // This code path is only used when inserting precisely one node. It needs more
      // comparison logic, but we can probably lean on the cases where this code path
      // is actually used.

      if (candidate) {
        return candidate;
      } else {
        return super.__appendNode(node);
      }
    }
    __appendHTML(html) {
      var candidateBounds = this.markerBounds();
      if (candidateBounds) {
        var first = candidateBounds.firstNode();
        var last = candidateBounds.lastNode();
        var newBounds = new ConcreteBounds(this.element, first.nextSibling, last.previousSibling);
        var possibleEmptyMarker = this.remove(first);
        this.remove(last);
        if (possibleEmptyMarker !== null && isEmpty$1(possibleEmptyMarker)) {
          this.candidate = this.remove(possibleEmptyMarker);
          if (this.candidate !== null) {
            this.clearMismatch(this.candidate);
          }
        }
        return newBounds;
      } else {
        return super.__appendHTML(html);
      }
    }
    remove(node) {
      var element = node.parentNode;
      var next = node.nextSibling;
      element.removeChild(node);
      return next;
    }
    markerBounds() {
      var _candidate = this.candidate;
      if (_candidate && isMarker(_candidate)) {
        var first = _candidate;
        var last = first.nextSibling;
        while (last && !isMarker(last)) {
          last = last.nextSibling;
        }
        return new ConcreteBounds(this.element, first, last);
      } else {
        return null;
      }
    }
    __appendText(string) {
      var {
        candidate
      } = this;
      if (candidate) {
        if (isTextNode(candidate)) {
          if (candidate.nodeValue !== string) {
            candidate.nodeValue = string;
          }
          this.candidate = candidate.nextSibling;
          return candidate;
        } else if (isSeparator(candidate)) {
          this.candidate = this.remove(candidate);
          return this.__appendText(string);
        } else if (isEmpty$1(candidate) && string === '') {
          this.candidate = this.remove(candidate);
          return this.__appendText(string);
        } else {
          this.clearMismatch(candidate);
          return super.__appendText(string);
        }
      } else {
        return super.__appendText(string);
      }
    }
    __appendComment(string) {
      var _candidate = this.candidate;
      if (_candidate && isComment(_candidate)) {
        if (_candidate.nodeValue !== string) {
          _candidate.nodeValue = string;
        }
        this.candidate = _candidate.nextSibling;
        return _candidate;
      } else if (_candidate) {
        this.clearMismatch(_candidate);
      }
      return super.__appendComment(string);
    }
    __openElement(tag) {
      var _candidate = this.candidate;
      if (_candidate && isElement(_candidate) && isSameNodeType(_candidate, tag)) {
        this.unmatchedAttributes = [].slice.call(_candidate.attributes);
        return _candidate;
      } else if (_candidate) {
        if (isElement(_candidate) && _candidate.tagName === 'TBODY') {
          this.pushElement(_candidate, null);
          this.currentCursor.injectedOmittedNode = true;
          return this.__openElement(tag);
        }
        this.clearMismatch(_candidate);
      }
      return super.__openElement(tag);
    }
    __setAttribute(name, value, namespace) {
      var unmatched = this.unmatchedAttributes;
      if (unmatched) {
        var attr = findByName(unmatched, name);
        if (attr) {
          if (attr.value !== value) {
            attr.value = value;
          }
          unmatched.splice(unmatched.indexOf(attr), 1);
          return;
        }
      }
      return super.__setAttribute(name, value, namespace);
    }
    __setProperty(name, value) {
      var unmatched = this.unmatchedAttributes;
      if (unmatched) {
        var attr = findByName(unmatched, name);
        if (attr) {
          if (attr.value !== value) {
            attr.value = value;
          }
          unmatched.splice(unmatched.indexOf(attr), 1);
          return;
        }
      }
      return super.__setProperty(name, value);
    }
    __flushElement(parent, constructing) {
      var {
        unmatchedAttributes: unmatched
      } = this;
      if (unmatched) {
        for (var i = 0; i < unmatched.length; i++) {
          this.constructing.removeAttribute(unmatched[i].name);
        }
        this.unmatchedAttributes = null;
      } else {
        super.__flushElement(parent, constructing);
      }
    }
    willCloseElement() {
      var {
        candidate,
        currentCursor
      } = this;
      if (candidate !== null) {
        this.clearMismatch(candidate);
      }
      if (currentCursor && currentCursor.injectedOmittedNode) {
        this.popElement();
      }
      super.willCloseElement();
    }
    getMarker(element, guid) {
      var marker = element.querySelector(`script[glmr="${guid}"]`);
      if (marker) {
        return marker;
      }
      return null;
    }
    __pushRemoteElement(element, cursorId, insertBefore) {
      var marker = this.getMarker(element, cursorId);
      if (insertBefore === undefined) {
        while (element.firstChild !== null && element.firstChild !== marker) {
          this.remove(element.firstChild);
        }
        insertBefore = null;
      }
      var cursor = new RehydratingCursor(element, null, this.blockDepth);
      this[CURSOR_STACK].push(cursor);
      if (marker === null) {
        this.disableRehydration(insertBefore);
      } else {
        this.candidate = this.remove(marker);
      }
      var block = new RemoteLiveBlock(element);
      return this.pushLiveBlock(block, true);
    }
    didAppendBounds(bounds) {
      super.didAppendBounds(bounds);
      if (this.candidate) {
        var last = bounds.lastNode();
        this.candidate = last && last.nextSibling;
      }
      return bounds;
    }
  }
  _exports.RehydrateBuilder = RehydrateBuilder;
  function isTextNode(node) {
    return node.nodeType === 3;
  }
  function isComment(node) {
    return node.nodeType === 8;
  }
  function isOpenBlock(node) {
    return node.nodeType === 8
    /* COMMENT_NODE */ && node.nodeValue.lastIndexOf('%+b:', 0) === 0;
  }
  function isCloseBlock(node) {
    return node.nodeType === 8
    /* COMMENT_NODE */ && node.nodeValue.lastIndexOf('%-b:', 0) === 0;
  }
  function getBlockDepth(node) {
    return parseInt(node.nodeValue.slice(4), 10);
  }
  function isElement(node) {
    return node.nodeType === 1;
  }
  function isMarker(node) {
    return node.nodeType === 8 && node.nodeValue === '%glmr%';
  }
  function isSeparator(node) {
    return node.nodeType === 8 && node.nodeValue === '%|%';
  }
  function isEmpty$1(node) {
    return node.nodeType === 8 && node.nodeValue === '% %';
  }
  function isSameNodeType(candidate, tag) {
    if (candidate.namespaceURI === "http://www.w3.org/2000/svg"
    /* SVG */) {
      return candidate.tagName === tag;
    }
    return candidate.tagName === tag.toUpperCase();
  }
  function findByName(array, name) {
    for (var i = 0; i < array.length; i++) {
      var attr = array[i];
      if (attr.name === name) return attr;
    }
    return undefined;
  }
  function rehydrationBuilder(env, cursor) {
    return RehydrateBuilder.forInitialRender(env, cursor);
  }
});